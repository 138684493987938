import { useQuery } from '@tanstack/react-query'
import { ReactNode, useEffect } from 'react'
import React from 'react'
import { request } from '../../configs/request'
interface IProps {
  url: string
  params?: object
  children: (props: any) => ReactNode
  onSuccess?: (data: any) => void
  onError?: (data: any) => void
  hideLoading?: boolean
}

function GetContainer(props: IProps) {
  const { data, isLoading, error, isError, refetch, isSuccess } = useQuery({
    queryKey: [props.url, ...(props?.params ? Object.values(props.params) : [])],
    queryFn: async () => {
      const response: any = await request({
        url: props.url,
        params: props.params,
        method: 'GET'
      })
      return response.data
    },
    enabled: !!props.url
  })
  useEffect(() => {
    if (isSuccess && data && props.onSuccess) {
      props.onSuccess && props.onSuccess(data)
    } else if (isError) {
      // @ts-ignore
      if (error.response?.data?.detail === 'Authentication credentials were not provided.') {
        // logout()
      }
    }
  }, [data, isSuccess, error, isError])

  return (
    <>
      {props.children({
        data: data,
        isLoading,
        error,
        isError,
        refetch
      })}
    </>
  )
}

export default GetContainer
