import { useContext } from 'react'
import Form from '../../../components/form'
import { AuthContext } from '../../../context/AuthContext'
import * as Yup from 'yup'
import CustomInput from '../../../components/form/custom-input'
import { MEDIA_URL } from '../../../configs/request'
import CustomTextarea from '../../../components/form/custom-textarea'
import toast from 'react-hot-toast'
import auth from '../../../configs/auth'
const validationSchema = Yup.object().shape({})
const validationSchemaPassword = Yup.object().shape({
  old_password: Yup.string().required('This field is required!'),
  password: Yup.string().required('Password is required').min(6, 'Password length should be at least 6 characters'),
  password_confirmation: Yup.string()
    .required('Confirm Password is required')
    .min(6, 'Password length should be at least 6 characters')
    .oneOf([Yup.ref('password')], 'Passwords do not match')
})
const defaultValue = {
  old_password: '',
  password: '',
  password_confirmation: ''
}
export const TeacherSettings = () => {
  const { user, setUser } = useContext(AuthContext)
  return (
    <div className='settings-form'>
      <Form
        url='teacher/profile/edit'
        getUrl='teacher/profile'
        initialValues={user}
        validationSchema={validationSchema}
        method='PUT'
        onSuccess={async response => {
          toast.success('Profile changed successfully!')
          console.log(response, 'onsuccess')
          await localStorage.setItem(auth.storageTokenKeyName, response.data.access_token)
          setUser({ ...response.data.user })
          await window.localStorage.setItem('userData', JSON.stringify(response.data.user))
        }}
      >
        {({ form, handleFinish }) => {
          const {
            control,
            formState: { errors },
            handleSubmit,
            setValue,
            watch
          } = form
          return (
            <div className='settings-form__item'>
              <div className='settings-title'>Shaxsiy ma’lumot</div>
              <div className='settings-wrap'>
                <div className='settings-item'>
                  <CustomInput
                    control={control}
                    name='firstname'
                    label='First name'
                    disabled
                    placeholder='First name'
                    error={errors?.firstname}
                  />
                </div>

                <div className='settings-item'>
                  <CustomInput
                    control={control}
                    name='lastname'
                    label='Last name'
                    disabled
                    placeholder='Last name'
                    error={errors?.lastname}
                  />
                </div>
              </div>
              <img style={{ width: 150, height: 150 }} src={MEDIA_URL + watch('photo')} />

              <div className='settings-wrap'>
                <div className='settings-item'>
                  <CustomInput
                    control={control}
                    disabled
                    name='email'
                    label='Email'
                    placeholder='Email'
                    error={errors?.email}
                  />
                </div>
                <div className='settings-item'>
                  <CustomInput
                    control={control}
                    disabled
                    name='phone'
                    label='Phone'
                    placeholder='phone'
                    error={errors?.phone}
                  />
                </div>
              </div>
              <div className='settings-wrap'>
                <div className='settings-item'>
                  <CustomInput
                    control={control}
                    name='postal'
                    label='Postal code'
                    disabled
                    placeholder='Postal code'
                    error={errors?.postal}
                  />
                </div>
                <div className='settings-item'></div>
              </div>
              <div className='settings-wrap'>
                <div className='settings-item'>
                  <CustomInput
                    control={control}
                    name='address'
                    label='Address'
                    placeholder='Address'
                    error={errors?.address}
                  />
                </div>
              </div>
              <div className='settings-wrap'>
                <div className='settings-item'>
                  <CustomInput
                    control={control}
                    disabled
                    name='birthday'
                    label='Birthday'
                    placeholder='Birthday'
                    error={errors?.birthday}
                  />
                </div>
                <div className='settings-item'>
                  <div className='settings-item__name'>Jinsi</div>
                  <div className='filter-gender'>
                    <label htmlFor='male'>
                      <input type='radio' checked={watch('gender') === 'male'} name='gender' id='male' />
                      <span className='btn btn-white'>Erkak</span>
                    </label>
                    <label htmlFor='female'>
                      <input type='radio' name='gender' id='female' checked={watch('gender') === 'female'} />
                      <span className='btn btn-white'>Ayol</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className='settings-wrap'>
                <div className='settings-item'>
                  <CustomInput
                    control={control}
                    name='timezone'
                    label='Timezone'
                    disabled
                    placeholder='Timezone'
                    error={errors?.timezone}
                  />
                </div>
                <div className='settings-item'>
                  <div className='settings-item__name'>Eslatma</div>
                  <div className='filter-gender'>
                    <label htmlFor='yes'>
                      <input type='radio' name='reminder' checked={watch('subscription')} id='yes' />
                      <span className='btn btn-white'>Qabo’l qilaman</span>
                    </label>
                    <label htmlFor='no'>
                      <input type='radio' name='reminder' checked={!watch('subscription')} id='no' />
                      <span className='btn btn-white'>Qabo’l qilmayman</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className='settings-wrap'>
                <div className='settings-item'>
                  <CustomTextarea
                    control={control}
                    name='info.bio'
                    label='Bio'
                    placeholder='Bio'
                    // @ts-ignore
                    error={errors?.info?.bio}
                  />
                </div>
              </div>
              <button className='settings-btn btn btn-orange' onClick={handleSubmit(data => handleFinish(data))}>
                Saqlash
              </button>
            </div>
          )
        }}
      </Form>
      <Form
        url='teacher/profile/change_password'
        initialValues={defaultValue}
        validationSchema={validationSchemaPassword}
        method='PUT'
        onSuccess={data => {
          toast.success('Password changed successfully!')
        }}
      >
        {({ form, handleFinish }) => {
          const {
            control,
            formState: { errors },
            handleSubmit,
            setValue,
            watch
          } = form
          return (
            <div className='settings-form__item settings-form__item-full'>
              <div className='settings-title'>Parolni yangilash</div>
              <div className='settings-wrap'>
                <div className='settings-item'>
                  <CustomInput
                    control={control}
                    name='old_password'
                    label='Old password'
                    placeholder='Old password'
                    error={errors?.old_password}
                  />
                </div>
                <div className='settings-item'>
                  <CustomInput
                    control={control}
                    name='password'
                    label='Password'
                    placeholder='Password'
                    error={errors?.password}
                  />
                </div>
                <div className='settings-item'>
                  <CustomInput
                    control={control}
                    name='password_confirmation'
                    label='Password confirmation'
                    placeholder='Password confirmation'
                    error={errors?.password_confirmation}
                  />
                </div>
              </div>
              <button onClick={handleSubmit(data => handleFinish(data))} className='settings-btn btn btn-orange'>
                Yangi parolni saqlash
              </button>
            </div>
          )
        }}
      </Form>
    </div>
  )
}
