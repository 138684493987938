import React, { SVGProps } from 'react'

function Message(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.0404 7.13287C4.77451 7 5.74936 7 7.22222 7H8.77778C10.9558 7 12.0448 7 12.8766 7.42964C13.6084 7.80757 14.2033 8.4106 14.5761 9.15232C15 9.99554 15 11.0994 15 13.3071V15.4348C15 15.6552 15 15.7655 14.9956 15.8586C14.9253 17.3646 14.0255 18.6473 12.7481 19.2486M4.0404 7.13287C3.68896 7.19648 3.39269 7.29055 3.12337 7.42964C2.39163 7.80757 1.7967 8.4106 1.42386 9.15232C1 9.99554 1 11.0994 1 13.3071V16.6406C1 18.2828 2.31338 19.6141 3.93351 19.6141H4.40154C5.00751 19.6141 5.42186 20.2345 5.19681 20.8048C4.87911 21.6098 5.79383 22.3377 6.48993 21.8337L8.5205 20.3635C8.54121 20.3485 8.55157 20.341 8.56175 20.3337C9.21041 19.8704 9.98339 19.6193 10.7769 19.6142C10.7894 19.6141 10.806 19.6141 10.8392 19.6141C11.0818 19.6141 11.2031 19.6141 11.295 19.6097C11.8125 19.5849 12.3032 19.4581 12.7481 19.2486M4.0404 7.13287C4.09617 6.08171 4.22894 5.35029 4.54497 4.73005C5.02433 3.78924 5.78924 3.02433 6.73005 2.54497C7.79961 2 9.19974 2 12 2H14C16.8003 2 18.2004 2 19.27 2.54497C20.2108 3.02433 20.9757 3.78924 21.455 4.73005C22 5.79961 22 7.19974 22 10V14.2283C22 16.3114 20.3114 18 18.2283 18H17.6266C16.8475 18 16.3147 18.7869 16.6041 19.5103C17.0126 20.5314 15.8365 21.4546 14.9415 20.8154L12.7481 19.2486" stroke="currentColor" stroke-width="1.5"/>
    <path d="M6 14C6 14.5523 5.55228 15 5 15C4.44772 15 4 14.5523 4 14C4 13.4477 4.44772 13 5 13C5.55228 13 6 13.4477 6 14Z" fill="currentColor"/>
    <path d="M9 14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14C7 13.4477 7.44772 13 8 13C8.55228 13 9 13.4477 9 14Z" fill="currentColor"/>
    <path d="M12 14C12 14.5523 11.5523 15 11 15C10.4477 15 10 14.5523 10 14C10 13.4477 10.4477 13 11 13C11.5523 13 12 13.4477 12 14Z" fill="currentColor"/>
  </svg>
  )
}

export default Message
