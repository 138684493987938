import React from 'react'
import TeachersDate from '../teachers-each-date'

interface TeacherEachProps {}

const TeachersEach = () => {
  return (
    <li className='teachers-item'>
      <div className='teachers-item__wrap'>
        <div className='teachers-item__img'>
          <img src={require('../../../../../assets/img/mock/teacher.jpg')} alt='teacher' />
        </div>
        <a href='teachers-single.html' className='teachers-item__btn btn btn-lightblue'>
          Profilni ko’rish
        </a>
      </div>
      <div className='teachers-item__wrap'>
        <div className='mark'>
          <span className='active'>
            <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                fill='currentColor'
              />
            </svg>
          </span>
          <span className='active'>
            <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                fill='currentColor'
              />
            </svg>
          </span>
          <span className='active'>
            <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                fill='currentColor'
              />
            </svg>
          </span>
          <span className='active'>
            <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                fill='currentColor'
              />
            </svg>
          </span>
          <span>
            <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                fill='currentColor'
              />
            </svg>
          </span>
        </div>
        <div className='teachers-item__name'>Myesha Levy</div>
        <div className='teachers-item__pos'>O’qituvchi</div>
        <div className='teachers-item__desc'>
          Game-Based Learning,Phonics Bootcamp,Speaking And Listening Bootcamp,ELA Standard,Story Time,Writing
          Bootcamp,Book Club,Book Worm,ELA Advanced
        </div>
        <div className='teachers-item__courses'>
          <div className='course-type'>
            <span className='course-type__ico'>
              <svg width={18} height={18} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M6.375 7.87477H11.625M5.25 13.8223H8.25L11.5875 16.0423C11.7001 16.1173 11.831 16.1604 11.9662 16.167C12.1015 16.1735 12.2359 16.1432 12.3552 16.0793C12.4746 16.0154 12.5743 15.9203 12.6438 15.8041C12.7134 15.688 12.7501 15.5551 12.75 15.4198V13.8223C15 13.8223 16.5 12.3223 16.5 10.0723V5.57227C16.5 3.32227 15 1.82227 12.75 1.82227H5.25C3 1.82227 1.5 3.32227 1.5 5.57227V10.0723C1.5 12.3223 3 13.8223 5.25 13.8223V13.8223Z'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeMiterlimit={10}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </span>
            <span>Speaking Course</span>
          </div>
          <div className='course-type orange'>
            <span className='course-type__ico'>
              <svg width={18} height={18} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M6.375 7.87477H11.625M5.25 13.8223H8.25L11.5875 16.0423C11.7001 16.1173 11.831 16.1604 11.9662 16.167C12.1015 16.1735 12.2359 16.1432 12.3552 16.0793C12.4746 16.0154 12.5743 15.9203 12.6438 15.8041C12.7134 15.688 12.7501 15.5551 12.75 15.4198V13.8223C15 13.8223 16.5 12.3223 16.5 10.0723V5.57227C16.5 3.32227 15 1.82227 12.75 1.82227H5.25C3 1.82227 1.5 3.32227 1.5 5.57227V10.0723C1.5 12.3223 3 13.8223 5.25 13.8223V13.8223Z'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeMiterlimit={10}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </span>
            <span>Speaking Course</span>
          </div>
        </div>
      </div>
      <div className='teachers-item__wrap'>
        <TeachersDate onChange={() => {}} />
      </div>
    </li>
  )
}

export default TeachersEach
