export const Students = () => (
  <>
    {' '}
    <div className='students-head'>
      <div className='students-info'>
        <div className='students-info__photo'>
          <img src={require('../../../assets/img/mock/student.jpg')} alt='student' />
        </div>
        <div className='students-info__wrap'>
          <div>Madina</div>
          <p>Profile</p>
        </div>
      </div>
      <ul className='students-menu'>
        <li className='current'>Basic info</li>
        <li>Class history</li>
        <li>Reservation</li>
        <li>Student notes</li>
      </ul>
    </div>
    <div className='students-tabs'>
      <div className='students-basic'>
        <h3 className='students__title'>Basic info</h3>
        <ul className='students-basic__list'>
          <li>
            <div className='students-basic__name'>Id</div>
            <div className='students-basic__info'>3271</div>
          </li>
          <li>
            <div className='students-basic__name'>Name</div>
            <div className='students-basic__info'>Kousei Fujiseki</div>
          </li>
          <li>
            <div className='students-basic__name'>Gender</div>
            <div className='students-basic__info'>male</div>
          </li>
          <li>
            <div className='students-basic__name'>Age</div>
            <div className='students-basic__info'>11 years old (2011-04-28)</div>
          </li>
          <li className='students-basic__program'>
            <div className='students-basic__name'>Programs</div>
            <table className='students-basic__table'>
              <tbody>
                <tr>
                  <th>Program</th>
                  <th>Next Lesson</th>
                  <th>Previous Lesson</th>
                </tr>
                <tr>
                  <td>Math Standard</td>
                  <td>(Math Standard) 1-7-133 Level I Lesson 1-13 True and False Statements</td>
                  <td>(Math Standard) 1-7-1 2 Level 1 Lesson 1-12 Find Missing Parts of Ten</td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
      </div>
      <div className='students-class'>
        <h3 className='students__title'>Class History</h3>
        <div className='history-table'>
          <div className='history-table__head'>
            <div>Date</div>
            <div>Program</div>
            <div>Teacher</div>
            <div>Lesson Title</div>
          </div>
          <div className='history-table__row'>
            <div className='history-table__item'>2023#1# 14% 18:01</div>
            <div className='history-table__item'>Game-Bassed Learning</div>
            <div className='history-table__item'>Akbar Bakhromov</div>
            <div className='history-table__item'>Elementary-3-17 Airplane</div>
          </div>
          <div className='history-table__row'>
            <div className='history-table__item'>2023#1# 14% 18:01</div>
            <div className='history-table__item'>Game-Bassed Learning</div>
            <div className='history-table__item'>Akbar Bakhromov</div>
            <div className='history-table__item'>Elementary-3-17 Airplane</div>
          </div>
          <div className='history-table__row'>
            <div className='history-table__item'>2023#1# 14% 18:01</div>
            <div className='history-table__item'>Game-Bassed Learning</div>
            <div className='history-table__item'>Akbar Bakhromov</div>
            <div className='history-table__item'>Elementary-3-17 Airplane</div>
          </div>
          <div className='history-table__row'>
            <div className='history-table__item'>2023#1# 14% 18:01</div>
            <div className='history-table__item'>Game-Bassed Learning</div>
            <div className='history-table__item'>Akbar Bakhromov</div>
            <div className='history-table__item'>Elementary-3-17 Airplane</div>
          </div>
        </div>
        <div className='pagination'>
          <a href='#' className='arrow arrow-left'>
            <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M14 8L10 12L14 16'
                stroke='currentColor'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </a>
          <ul>
            <li>
              <a href='#' className='current'>
                1
              </a>
            </li>
            <li>
              <a href='#'>2</a>
            </li>
            <li>
              <a href='#'>3</a>
            </li>
            <li>
              <a href='#'>4</a>
            </li>
          </ul>
          <span> ... </span>
          <a href='#' className='arrow arrow-right'>
            <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M10 16L14 12L10 8'
                stroke='currentColor'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </a>
        </div>
      </div>
      <div className='students-reservation'>
        <h3 className='students__title'>Reservations</h3>
        <div className='history-table'>
          <div className='history-table__head'>
            <div>Date</div>
            <div>Program</div>
            <div>Teacher</div>
            <div>Lesson Title</div>
          </div>
          <div className='history-table__row'>
            <div className='history-table__item'>2023#1# 14% 18:01</div>
            <div className='history-table__item'>Game-Bassed Learning</div>
            <div className='history-table__item'>Akbar Bakhromov</div>
            <div className='history-table__item'>Elementary-3-17 Airplane</div>
          </div>
          <div className='history-table__row'>
            <div className='history-table__item'>2023#1# 14% 18:01</div>
            <div className='history-table__item'>Game-Bassed Learning</div>
            <div className='history-table__item'>Akbar Bakhromov</div>
            <div className='history-table__item'>Elementary-3-17 Airplane</div>
          </div>
          <div className='history-table__row'>
            <div className='history-table__item'>2023#1# 14% 18:01</div>
            <div className='history-table__item'>Game-Bassed Learning</div>
            <div className='history-table__item'>Akbar Bakhromov</div>
            <div className='history-table__item'>Elementary-3-17 Airplane</div>
          </div>
        </div>
      </div>
      <div className='students-notes'>
        <h3 className='students__title'>Student Notes</h3>
        <div className='students-notes__select'></div>
        <ul className='students-notes__list'>
          <li className='students-notes__item'>
            <h4 className='students-notes__title'>Akbar Bakhromov at 2023-01-15 13:15-0600</h4>
            <ul className='students-notes__info'>
              <li>
                <span>Today’s lesson number:</span> E -3-17
              </li>
              <li>
                <span>Student accomplished:</span> We kept working on his arena and added an antrance to it He could
                play very well
              </li>
              <li>
                <span>The student is having difficulty with:</span> He needs to work onhis grammar
              </li>
              <li>
                <span>Notes to the next teacher:</span> Please proceed to the next lesson
              </li>
              <li>
                <span>Next Lesson number:</span> E -3-17
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </>
)
