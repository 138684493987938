import React, { useState } from 'react'
import GetContainer from '../../get-container'
import Select from 'react-select'

interface IProps {
  url: string
  getLabel: (data: any) => any
  getValue: (data: any) => any
  value: any
  setValue: (data: any) => void
  params?: any
}

function AsyncSelect(props: IProps) {
  const [search, setSearch] = useState('')
  return (
    <GetContainer url={props.url} params={{ search: search, per_page: 100, ...(props.params && props?.params) }}>
      {({ data }) => (
        <Select
          options={data?.data || []}
          getOptionLabel={props.getLabel}
          getOptionValue={props.getValue}
          inputValue={search}
          onInputChange={e => setSearch(e)}
          value={props.value}
          onChange={e => props.setValue(e)}
          isClearable
        />
      )}
    </GetContainer>
  )
}

export default AsyncSelect
