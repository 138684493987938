import Box from '../assets/img/icons/menu-icons/Box'
import Calendar from '../assets/img/icons/menu-icons/Calendar'
import Coins from '../assets/img/icons/menu-icons/Coins'
import Comment from '../assets/img/icons/menu-icons/Comment'
import Home from '../assets/img/icons/menu-icons/Home'
import HomeWork from '../assets/img/icons/menu-icons/HomeWork'
import Invite from '../assets/img/icons/menu-icons/Invite'
import Library from '../assets/img/icons/menu-icons/Library'
import List from '../assets/img/icons/menu-icons/List'
import LiveLessons from '../assets/img/icons/menu-icons/LiveLessons'
import Lock from '../assets/img/icons/menu-icons/Lock'
import NewList from '../assets/img/icons/menu-icons/NewList'
import Payment from '../assets/img/icons/menu-icons/Payment'
import Profile from '../assets/img/icons/menu-icons/Profile'
import School from '../assets/img/icons/menu-icons/School'
import Settings from '../assets/img/icons/menu-icons/Settings'
import Support from '../assets/img/icons/menu-icons/Support'
import Teach from '../assets/img/icons/menu-icons/Teach'

export const sideMenuData = [
  {
    icon: <Home />,
    path: '/home',
    title: 'Bosh sahifa',
    count: 12
  },
  {
    icon: <School />,
    path: '/school',
    title: 'Maktabim',
    count: 24
  },
  {
    icon: <Support />,
    path: '/support',
    title: 'Support',
    count: 24
  },
  {
    icon: <Profile />,
    path: '/profile',
    title: 'Profile'
  }
]

export const profileMenuData = [
  {
    icon: <Calendar />,
    path: '/calendar',
    title: 'Dars jadvali',
    count: 12
  },
  {
    icon: <HomeWork />,
    path: '/homework',
    title: 'Uyga vazifa',
    count: 24
  },
  {
    icon: <LiveLessons />,
    path: '/live-lessons',
    title: 'Live lessons',
    count: 24
  },
  {
    icon: <Library />,
    path: '/library',
    title: 'Kutubxona'
  }
]

export const homeMenuData = [
  {
    icon: <Lock />,
    path: '/lesson-history',
    title: 'Dars tarixi',
    count: 12
  },
  {
    icon: <Comment />,
    path: '/teacher-reviews',
    title: 'O`qituvchi fikrlari',
    count: 24
  }
]

export const schoolMenuData = [
  {
    icon: <Teach />,
    path: '/teachers',
    title: 'O`qituvchilar',
    count: 12
  },
  {
    icon: <List />,
    path: '/courses',
    title: 'Kurslar ro`xati',
    count: 24
  },
  {
    icon: <Lock />,
    path: '/individual-lessons',
    title: 'Individual darslar',
    count: 24
  },
  {
    icon: <Box />,
    path: '/group-courses',
    title: 'Guruh darslar',
    count: 24
  },
  {
    icon: <NewList />,
    path: '/new-courses',
    title: 'Yangi kurslar',
    count: 24
  }
]
export const supportMenuData = [
  {
    icon: <Teach />,
    path: '/requirements',
    title: 'Requirements'
  },
  {
    icon: <List />,
    path: '/how-to-use',
    title: 'How to use'
  },
  {
    icon: <Lock />,
    path: '/account-manual',
    title: 'Account manual'
  },
  {
    icon: <Box />,
    path: '/faq',
    title: 'FAQ'
  }
]
export const profilesMenuData = [
  {
    icon: <Settings />,
    path: '/settings',
    title: 'Sozlanmalar'
  },
  {
    icon: <Payment />,
    path: '/payment',
    title: 'To`lov'
  },
  {
    icon: <Coins />,
    path: '/coin',
    title: 'Tangalar'
  },
  {
    icon: <Invite />,
    path: '/invite',
    title: 'Do`stlarni taklif'
  }
]

export const teachersMenuData = [
  {
    icon: <Calendar />,
    path: '/home',
    title: 'Oylik dars jadvali'
  },
  {
    icon: <Lock />,
    path: '/classes',
    title: 'Kunlik darslar'
  },
  {
    icon: <Comment />,
    path: '/lessons',
    title: 'Dars rejalari'
  },
  {
    icon: <Profile />,
    path: '/students',
    title: `O'quvchilar`
  },
  {
    icon: <Comment />,
    path: '/payments',
    title: 'To`lovlar'
  },
  {
    icon: <Comment />,
    path: '/settings',
    title: 'Sozlama'
  }
]
