import Calendar from '../../../assets/img/icons/Calendar'
import Coin from '../../../assets/img/icons/Coin'

export const IndividualLessons = () => (
  <div className='individual-wrap'>
    <form className='filter'>
      <div className='filter__head'>
        <div className='filter__wrap'>
          <div className='filter__title'>Kimni qidirmoqdasiz?</div>
          <div className='filter-search'>
            <div className='search-input'>
              <input type='text' className='search-input__input' placeholder='Qidirish ...' />
              <svg
                width={20}
                height={20}
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className='search-input__ico'
              >
                <path
                  d='M18.3327 18.3337L16.666 16.667M9.58268 17.5003C10.6223 17.5003 11.6518 17.2956 12.6123 16.8977C13.5728 16.4999 14.4455 15.9167 15.1806 15.1816C15.9157 14.4465 16.4989 13.5737 16.8967 12.6132C17.2946 11.6527 17.4993 10.6233 17.4993 9.58366C17.4993 8.54403 17.2946 7.51458 16.8967 6.55408C16.4989 5.59359 15.9157 4.72086 15.1806 3.98573C14.4455 3.2506 13.5728 2.66746 12.6123 2.26961C11.6518 1.87176 10.6223 1.66699 9.58268 1.66699C7.48305 1.66699 5.46942 2.50107 3.98475 3.98573C2.50009 5.47039 1.66602 7.48403 1.66602 9.58366C1.66602 11.6833 2.50009 13.6969 3.98475 15.1816C5.46942 16.6662 7.48305 17.5003 9.58268 17.5003V17.5003Z'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </div>
        </div>
        <div className='filter-gender'>
          <label htmlFor='male'>
            <input type='radio' name='gender' id='male' />
            <span className='btn btn-white'>Erkak</span>
          </label>
          <label htmlFor='female'>
            <input type='radio' name='gender' id='female' />
            <span className='btn btn-white'>Ayol</span>
          </label>
        </div>
      </div>
      <div className='filter__main'>
        <div className='filter-date'>
          <div className='filter-title'>Sana</div>
          <div className='filter-wrap'>
            <input type='text' />
            <input type='text' />
          </div>
        </div>
        <div className='filter-time'>
          <div className='filter-title'>Vaqt</div>
          <div className='filter-wrap'>
            <input type='text' />
            <input type='text' />
          </div>
        </div>
        <div className='filter-days'>
          <div className='filter-title'>Hafta kuni</div>
          <div className='filter-wrap'>
            <label htmlFor='day1'>
              <input type='checkbox' name='week' id='day1' />
              <span>Du</span>
            </label>
            <label htmlFor='day2'>
              <input type='checkbox' name='week' id='day2' />
              <span>Se</span>
            </label>
            <label htmlFor='day3'>
              <input type='checkbox' name='week' id='day3' />
              <span>Ch</span>
            </label>
            <label htmlFor='day4'>
              <input type='checkbox' name='week' id='day4' />
              <span>Pa</span>
            </label>
            <label htmlFor='day5'>
              <input type='checkbox' name='week' id='day5' />
              <span>Ju</span>
            </label>
            <label htmlFor='day6'>
              <input type='checkbox' name='week' id='day6' />
              <span>Sh</span>
            </label>
            <label htmlFor='day7'>
              <input type='checkbox' name='week' id='day7' />
              <span>Ya</span>
            </label>
          </div>
        </div>
      </div>
      <div className='filter-courses'>
        <div className='filter-title'>Kurslar</div>
        <div className='filter-wrap'>
          <label htmlFor='th1'>
            <input type='checkbox' name='courses' id='th1' />
            <span>Math Standard</span>
          </label>
          <label htmlFor='fr2'>
            <input type='checkbox' name='courses' id='fr2' />
            <span>Phonics Bootcamp</span>
          </label>
          <label htmlFor='sa3'>
            <input type='checkbox' name='courses' id='sa3' />
            <span>Story Time</span>
          </label>
        </div>
      </div>
    </form>
    <ul className='individual-list'>
      <div className='individual-item'>
        <div className='individual-item__head'>
          <div className='individual-item__ico'>
            <svg width={34} height={34} viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M12.2139 14.948H21.7875M10.1625 25.7935H15.633L21.7191 29.8417C21.9245 29.9786 22.1632 30.0572 22.4097 30.0691C22.6563 30.081 22.9014 30.0257 23.1191 29.9092C23.3367 29.7927 23.5186 29.6193 23.6453 29.4075C23.7721 29.1957 23.839 28.9534 23.8389 28.7066V25.7935C27.9419 25.7935 30.6772 23.0582 30.6772 18.9553V10.7494C30.6772 6.64643 27.9419 3.91113 23.8389 3.91113H10.1625C6.05951 3.91113 3.32422 6.64643 3.32422 10.7494V18.9553C3.32422 23.0582 6.05951 25.7935 10.1625 25.7935V25.7935Z'
                stroke='currentColor'
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className='individual-item__wrap'>
            <div className='individual-item__name'>Speaking Course</div>
            <div className='individual-item__price'>
              <Coin />
              <span>100 coin</span>
            </div>
          </div>
        </div>
        <div className='individual-item__date'>
          <Calendar />
          <span>5 Noyabr ( dushanba )</span>
        </div>
        <div className='individual-item__time'>
          <Calendar />
          <span>16:30 - 16:55</span>
        </div>
        <div className='individual-item__user'>
          <img src={require('../../../assets/img/mock/student.jpg')} alt='student' />
          <span>Nicole Cilliers</span>
        </div>
        <div className='individual-item__btn'>
          <a href='#' className='btn'>
            Band qilish
          </a>
        </div>
        <div className='individual-popup'>
          <div className='individual-popup__content'>
            <div className='individual-popup__title'>Qo’yidagi darsni band qilasizmi?</div>
            <div className='individual-popup__text'>
              <p>ELA Advanced with Allison Cruz</p>
              <p>Nov 8(Tue) 09:30-09:55</p>
            </div>
            <div className='individual-popup__btns'>
              <a href='#' className='btn btn-gray'>
                Yo’q
              </a>
              <a href='#' className='btn'>
                Ha, band qilaman
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='individual-item'>
        <div className='individual-item__head'>
          <div className='individual-item__ico'>
            <svg width={34} height={34} viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M12.2139 14.948H21.7875M10.1625 25.7935H15.633L21.7191 29.8417C21.9245 29.9786 22.1632 30.0572 22.4097 30.0691C22.6563 30.081 22.9014 30.0257 23.1191 29.9092C23.3367 29.7927 23.5186 29.6193 23.6453 29.4075C23.7721 29.1957 23.839 28.9534 23.8389 28.7066V25.7935C27.9419 25.7935 30.6772 23.0582 30.6772 18.9553V10.7494C30.6772 6.64643 27.9419 3.91113 23.8389 3.91113H10.1625C6.05951 3.91113 3.32422 6.64643 3.32422 10.7494V18.9553C3.32422 23.0582 6.05951 25.7935 10.1625 25.7935V25.7935Z'
                stroke='currentColor'
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className='individual-item__wrap'>
            <div className='individual-item__name'>Speaking Course</div>
            <div className='individual-item__price'>
              <Coin />
              <span>100 coin</span>
            </div>
          </div>
        </div>
        <div className='individual-item__date'>
          <Calendar />
          <span>5 Noyabr ( dushanba )</span>
        </div>
        <div className='individual-item__time'>
          <Calendar />
          <span>16:30 - 16:55</span>
        </div>
        <div className='individual-item__user'>
          <img src={require('../../../assets/img/mock/student.jpg')} alt='student' />
          <span>Nicole Cilliers</span>
        </div>
        <div className='individual-item__btn'>
          <a href='#' className='btn'>
            Band qilish
          </a>
        </div>
        <div className='individual-popup'>
          <div className='individual-popup__content'>
            <div className='individual-popup__title'>Qo’yidagi darsni band qilasizmi?</div>
            <div className='individual-popup__text'>
              <p>ELA Advanced with Allison Cruz</p>
              <p>Nov 8(Tue) 09:30-09:55</p>
            </div>
            <div className='individual-popup__btns'>
              <a href='#' className='btn btn-gray'>
                Yo’q
              </a>
              <a href='#' className='btn'>
                Ha, band qilaman
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='individual-item'>
        <div className='individual-item__head'>
          <div className='individual-item__ico'>
            <svg width={34} height={34} viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M12.2139 14.948H21.7875M10.1625 25.7935H15.633L21.7191 29.8417C21.9245 29.9786 22.1632 30.0572 22.4097 30.0691C22.6563 30.081 22.9014 30.0257 23.1191 29.9092C23.3367 29.7927 23.5186 29.6193 23.6453 29.4075C23.7721 29.1957 23.839 28.9534 23.8389 28.7066V25.7935C27.9419 25.7935 30.6772 23.0582 30.6772 18.9553V10.7494C30.6772 6.64643 27.9419 3.91113 23.8389 3.91113H10.1625C6.05951 3.91113 3.32422 6.64643 3.32422 10.7494V18.9553C3.32422 23.0582 6.05951 25.7935 10.1625 25.7935V25.7935Z'
                stroke='currentColor'
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className='individual-item__wrap'>
            <div className='individual-item__name'>Speaking Course</div>
            <div className='individual-item__price'>
              <Coin />
              <span>100 coin</span>
            </div>
          </div>
        </div>
        <div className='individual-item__date'>
          <Calendar />
          <span>5 Noyabr ( dushanba )</span>
        </div>
        <div className='individual-item__time'>
          <Calendar />
          <span>16:30 - 16:55</span>
        </div>
        <div className='individual-item__user'>
          <img src={require('../../../assets/img/mock/student.jpg')} alt='student' />
          <span>Nicole Cilliers</span>
        </div>
        <div className='individual-item__btn'>
          <a href='#' className='btn'>
            Band qilish
          </a>
        </div>
        <div className='individual-popup'>
          <div className='individual-popup__content'>
            <div className='individual-popup__title'>Qo’yidagi darsni band qilasizmi?</div>
            <div className='individual-popup__text'>
              <p>ELA Advanced with Allison Cruz</p>
              <p>Nov 8(Tue) 09:30-09:55</p>
            </div>
            <div className='individual-popup__btns'>
              <a href='#' className='btn btn-gray'>
                Yo’q
              </a>
              <a href='#' className='btn'>
                Ha, band qilaman
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='individual-item'>
        <div className='individual-item__head'>
          <div className='individual-item__ico'>
            <svg width={34} height={34} viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M12.2139 14.948H21.7875M10.1625 25.7935H15.633L21.7191 29.8417C21.9245 29.9786 22.1632 30.0572 22.4097 30.0691C22.6563 30.081 22.9014 30.0257 23.1191 29.9092C23.3367 29.7927 23.5186 29.6193 23.6453 29.4075C23.7721 29.1957 23.839 28.9534 23.8389 28.7066V25.7935C27.9419 25.7935 30.6772 23.0582 30.6772 18.9553V10.7494C30.6772 6.64643 27.9419 3.91113 23.8389 3.91113H10.1625C6.05951 3.91113 3.32422 6.64643 3.32422 10.7494V18.9553C3.32422 23.0582 6.05951 25.7935 10.1625 25.7935V25.7935Z'
                stroke='currentColor'
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className='individual-item__wrap'>
            <div className='individual-item__name'>Speaking Course</div>
            <div className='individual-item__price'>
              <Coin />
              <span>100 coin</span>
            </div>
          </div>
        </div>
        <div className='individual-item__date'>
          <Calendar />
          <span>5 Noyabr ( dushanba )</span>
        </div>
        <div className='individual-item__time'>
          <Calendar />
          <span>16:30 - 16:55</span>
        </div>
        <div className='individual-item__user'>
          <img src={require('../../../assets/img/mock/student.jpg')} alt='student' />
          <span>Nicole Cilliers</span>
        </div>
        <div className='individual-item__btn'>
          <a href='#' className='btn'>
            Band qilish
          </a>
        </div>
        <div className='individual-popup'>
          <div className='individual-popup__content'>
            <div className='individual-popup__title'>Qo’yidagi darsni band qilasizmi?</div>
            <div className='individual-popup__text'>
              <p>ELA Advanced with Allison Cruz</p>
              <p>Nov 8(Tue) 09:30-09:55</p>
            </div>
            <div className='individual-popup__btns'>
              <a href='#' className='btn btn-gray'>
                Yo’q
              </a>
              <a href='#' className='btn'>
                Ha, band qilaman
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='individual-item'>
        <div className='individual-item__head'>
          <div className='individual-item__ico'>
            <svg width={34} height={34} viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M12.2139 14.948H21.7875M10.1625 25.7935H15.633L21.7191 29.8417C21.9245 29.9786 22.1632 30.0572 22.4097 30.0691C22.6563 30.081 22.9014 30.0257 23.1191 29.9092C23.3367 29.7927 23.5186 29.6193 23.6453 29.4075C23.7721 29.1957 23.839 28.9534 23.8389 28.7066V25.7935C27.9419 25.7935 30.6772 23.0582 30.6772 18.9553V10.7494C30.6772 6.64643 27.9419 3.91113 23.8389 3.91113H10.1625C6.05951 3.91113 3.32422 6.64643 3.32422 10.7494V18.9553C3.32422 23.0582 6.05951 25.7935 10.1625 25.7935V25.7935Z'
                stroke='currentColor'
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className='individual-item__wrap'>
            <div className='individual-item__name'>Speaking Course</div>
            <div className='individual-item__price'>
              <Coin />
              <span>100 coin</span>
            </div>
          </div>
        </div>
        <div className='individual-item__date'>
          <Calendar />
          <span>5 Noyabr ( dushanba )</span>
        </div>
        <div className='individual-item__time'>
          <Calendar />
          <span>16:30 - 16:55</span>
        </div>
        <div className='individual-item__user'>
          <img src={require('../../../assets/img/mock/student.jpg')} alt='student' />
          <span>Nicole Cilliers</span>
        </div>
        <div className='individual-item__btn'>
          <a href='#' className='btn'>
            Band qilish
          </a>
        </div>
        <div className='individual-popup'>
          <div className='individual-popup__content'>
            <div className='individual-popup__title'>Qo’yidagi darsni band qilasizmi?</div>
            <div className='individual-popup__text'>
              <p>ELA Advanced with Allison Cruz</p>
              <p>Nov 8(Tue) 09:30-09:55</p>
            </div>
            <div className='individual-popup__btns'>
              <a href='#' className='btn btn-gray'>
                Yo’q
              </a>
              <a href='#' className='btn'>
                Ha, band qilaman
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='individual-item'>
        <div className='individual-item__head'>
          <div className='individual-item__ico'>
            <svg width={34} height={34} viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M12.2139 14.948H21.7875M10.1625 25.7935H15.633L21.7191 29.8417C21.9245 29.9786 22.1632 30.0572 22.4097 30.0691C22.6563 30.081 22.9014 30.0257 23.1191 29.9092C23.3367 29.7927 23.5186 29.6193 23.6453 29.4075C23.7721 29.1957 23.839 28.9534 23.8389 28.7066V25.7935C27.9419 25.7935 30.6772 23.0582 30.6772 18.9553V10.7494C30.6772 6.64643 27.9419 3.91113 23.8389 3.91113H10.1625C6.05951 3.91113 3.32422 6.64643 3.32422 10.7494V18.9553C3.32422 23.0582 6.05951 25.7935 10.1625 25.7935V25.7935Z'
                stroke='currentColor'
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className='individual-item__wrap'>
            <div className='individual-item__name'>Speaking Course</div>
            <div className='individual-item__price'>
              <Coin />
              <span>100 coin</span>
            </div>
          </div>
        </div>
        <div className='individual-item__date'>
          <Calendar />
          <span>5 Noyabr ( dushanba )</span>
        </div>
        <div className='individual-item__time'>
          <Calendar />
          <span>16:30 - 16:55</span>
        </div>
        <div className='individual-item__user'>
          <img src={require('../../../assets/img/mock/student.jpg')} alt='student' />
          <span>Nicole Cilliers</span>
        </div>
        <div className='individual-item__btn'>
          <a href='#' className='btn'>
            Band qilish
          </a>
        </div>
        <div className='individual-popup'>
          <div className='individual-popup__content'>
            <div className='individual-popup__title'>Qo’yidagi darsni band qilasizmi?</div>
            <div className='individual-popup__text'>
              <p>ELA Advanced with Allison Cruz</p>
              <p>Nov 8(Tue) 09:30-09:55</p>
            </div>
            <div className='individual-popup__btns'>
              <a href='#' className='btn btn-gray'>
                Yo’q
              </a>
              <a href='#' className='btn'>
                Ha, band qilaman
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='individual-item'>
        <div className='individual-item__head'>
          <div className='individual-item__ico'>
            <svg width={34} height={34} viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M12.2139 14.948H21.7875M10.1625 25.7935H15.633L21.7191 29.8417C21.9245 29.9786 22.1632 30.0572 22.4097 30.0691C22.6563 30.081 22.9014 30.0257 23.1191 29.9092C23.3367 29.7927 23.5186 29.6193 23.6453 29.4075C23.7721 29.1957 23.839 28.9534 23.8389 28.7066V25.7935C27.9419 25.7935 30.6772 23.0582 30.6772 18.9553V10.7494C30.6772 6.64643 27.9419 3.91113 23.8389 3.91113H10.1625C6.05951 3.91113 3.32422 6.64643 3.32422 10.7494V18.9553C3.32422 23.0582 6.05951 25.7935 10.1625 25.7935V25.7935Z'
                stroke='currentColor'
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className='individual-item__wrap'>
            <div className='individual-item__name'>Speaking Course</div>
            <div className='individual-item__price'>
              <Coin />
              <span>100 coin</span>
            </div>
          </div>
        </div>
        <div className='individual-item__date'>
          <Calendar />
          <span>5 Noyabr ( dushanba )</span>
        </div>
        <div className='individual-item__time'>
          <Calendar />
          <span>16:30 - 16:55</span>
        </div>
        <div className='individual-item__user'>
          <img src={require('../../../assets/img/mock/student.jpg')} alt='student' />
          <span>Nicole Cilliers</span>
        </div>
        <div className='individual-item__btn'>
          <a href='#' className='btn'>
            Band qilish
          </a>
        </div>
        <div className='individual-popup'>
          <div className='individual-popup__content'>
            <div className='individual-popup__title'>Qo’yidagi darsni band qilasizmi?</div>
            <div className='individual-popup__text'>
              <p>ELA Advanced with Allison Cruz</p>
              <p>Nov 8(Tue) 09:30-09:55</p>
            </div>
            <div className='individual-popup__btns'>
              <a href='#' className='btn btn-gray'>
                Yo’q
              </a>
              <a href='#' className='btn'>
                Ha, band qilaman
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='individual-item'>
        <div className='individual-item__head'>
          <div className='individual-item__ico'>
            <svg width={34} height={34} viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M12.2139 14.948H21.7875M10.1625 25.7935H15.633L21.7191 29.8417C21.9245 29.9786 22.1632 30.0572 22.4097 30.0691C22.6563 30.081 22.9014 30.0257 23.1191 29.9092C23.3367 29.7927 23.5186 29.6193 23.6453 29.4075C23.7721 29.1957 23.839 28.9534 23.8389 28.7066V25.7935C27.9419 25.7935 30.6772 23.0582 30.6772 18.9553V10.7494C30.6772 6.64643 27.9419 3.91113 23.8389 3.91113H10.1625C6.05951 3.91113 3.32422 6.64643 3.32422 10.7494V18.9553C3.32422 23.0582 6.05951 25.7935 10.1625 25.7935V25.7935Z'
                stroke='currentColor'
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className='individual-item__wrap'>
            <div className='individual-item__name'>Speaking Course</div>
            <div className='individual-item__price'>
              <Coin />
              <span>100 coin</span>
            </div>
          </div>
        </div>
        <div className='individual-item__date'>
          <Calendar />
          <span>5 Noyabr ( dushanba )</span>
        </div>
        <div className='individual-item__time'>
          <Calendar />
          <span>16:30 - 16:55</span>
        </div>
        <div className='individual-item__user'>
          <img src={require('../../../assets/img/mock/student.jpg')} alt='student' />
          <span>Nicole Cilliers</span>
        </div>
        <div className='individual-item__btn'>
          <a href='#' className='btn'>
            Band qilish
          </a>
        </div>
        <div className='individual-popup'>
          <div className='individual-popup__content'>
            <div className='individual-popup__title'>Qo’yidagi darsni band qilasizmi?</div>
            <div className='individual-popup__text'>
              <p>ELA Advanced with Allison Cruz</p>
              <p>Nov 8(Tue) 09:30-09:55</p>
            </div>
            <div className='individual-popup__btns'>
              <a href='#' className='btn btn-gray'>
                Yo’q
              </a>
              <a href='#' className='btn'>
                Ha, band qilaman
              </a>
            </div>
          </div>
        </div>
      </div>
    </ul>
  </div>
)
