import React from 'react'
import Balance from '../../../../assets/img/icons/Balanse'

function ProfileInfo() {
  return (
    <div className="profile-info">
        <div className="profile-info__wrap">
          <div className="profile-info__photo">
            <img src={require('../../../../assets/img/mock/student.jpg')} alt="student" />
          </div>
          <div className="profile-info__name">
            <div>Madina</div>
            <span>Profile</span>
          </div>
        </div>
        <div className="profile-info__balance">
        <Balance/>
          <span>Coin (5650)</span>
        </div>
      </div>
  )
}

export default ProfileInfo