export const Coin = () => (
  <div className='coin-info'>
    <div className='coin__title'>
      Next charge date: <span>次回の課金日は 01月20日 です。</span>
    </div>
    <div className='coin-table table'>
      <table>
        <tbody>
          <tr className='head'>
            <td>Date</td>
            <td>Amount</td>
            <td>Description</td>
          </tr>
          <tr>
            <td>2022.12.23</td>
            <td className='bold green'>+200</td>
            <td>Cancel gsa-class-1750529(Myesha Levy)</td>
          </tr>
          <tr>
            <td>2022.12.23</td>
            <td className='bold green'>+200</td>
            <td>Cancel gsa-class-1750529(Myesha Levy)</td>
          </tr>
          <tr>
            <td>2022.12.23</td>
            <td className='bold green'>+200</td>
            <td>Cancel gsa-class-1750529(Myesha Levy)</td>
          </tr>
          <tr>
            <td>2022.12.23</td>
            <td className='bold green'>+200</td>
            <td>Cancel gsa-class-1750529(Myesha Levy)</td>
          </tr>
          <tr>
            <td>2022.12.23</td>
            <td className='bold green'>+200</td>
            <td>Cancel gsa-class-1750529(Myesha Levy)</td>
          </tr>
          <tr>
            <td>2022.12.23</td>
            <td className='bold green'>+200</td>
            <td>Cancel gsa-class-1750529(Myesha Levy)</td>
          </tr>
          <tr>
            <td>2022.12.23</td>
            <td className='bold red'>+200</td>
            <td>Cancel gsa-class-1750529(Myesha Levy)</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className='pagination'>
      <a href='#' className='arrow arrow-left'>
        <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M14 8L10 12L14 16'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </a>
      <ul>
        <li>
          <a href='#' className='current'>
            1
          </a>
        </li>
        <li>
          <a href='#'>2</a>
        </li>
        <li>
          <a href='#'>3</a>
        </li>
        <li>
          <a href='#'>4</a>
        </li>
      </ul>
      <span> ... </span>
      <a href='#' className='arrow arrow-right'>
        <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M10 16L14 12L10 8'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </a>
    </div>
  </div>
)
