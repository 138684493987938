import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import Form from '../../../../components/form'
import CustomPasswordInput from '../../../../components/form/custom-password-input'

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Phone is required'),
  password_confirmation: Yup.string().required('Phone is required')
})
const initialValues = {
  password: '',
  password_confirmation: ''
}

interface IProps {
  token: string
  setToken: React.Dispatch<React.SetStateAction<string>>
  setPhone: React.Dispatch<React.SetStateAction<string>>
  setStep: React.Dispatch<React.SetStateAction<number>>
}

export const ResetPassword = (props: IProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      {/*  LOGIN	*/}
      <section className='login'>
        <div className='login__wrap'>
          <div className='login__logo'>
            <img src={require('../../../../assets/img/logo.png')} alt='Onschool' />
          </div>
          <Form
            url='forgot_password/new_password'
            initialValues={initialValues}
            validationSchema={validationSchema}
            method='post'
            onSuccess={data => {
              navigate('sign-in')
            }}
          >
            {({ form, handleFinish }) => {
              const {
                control,
                formState: { errors },
                handleSubmit
              } = form
              return (
                <div className='login-form login-form__centered'>
                  <div className='login-form__title'>{t('Forgot password')}</div>
                  <CustomPasswordInput
                    control={control}
                    name='password'
                    label='Password'
                    placeholder='Write password'
                    error={errors?.password}
                  />
                  <CustomPasswordInput
                    control={control}
                    name='password_confirmation'
                    label='Password confirmation'
                    placeholder='Write password confirmation'
                    error={errors?.password_confirmation}
                  />
                  <div className='login-form__submit'>
                    <button
                      onClick={handleSubmit(data => {
                        handleFinish({ ...data, token: props.token })
                      })}
                      className='btn btn-orange'
                    >
                      {t('Submit')}
                    </button>
                  </div>
                </div>
              )
            }}
          </Form>
        </div>
      </section>
    </>
  )
}
