//@ts-nocheck
import React, { useState } from 'react'
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css'
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker'

interface TeachersDateProps {
  onChange: () => void
}

//UZB
// const myCustomLocale = {
//   // months list by order
//   months: [
//   'Yanvar',
//   'Fevral',
//   'Mart',
//   'Aprel',
//   'May',
//   'Iyun',
//   'Iyul',
//   'Avgust',
//   'Sentyabr',
//   'Oktyabr',
//   'Noyabr',
//   'Dekabr',
//   ],

//   // week days by order
//   weekDays: [
//     {
//       name: 'Dushanba', // used for accessibility
//       short: 'D', // displayed at the top of days' rows
//     },
//     {
//       name: 'Seshanba',
//       short: 'S',
//     },
//     {
//       name: 'Chorshanba',
//       short: 'C',
//     },
//     {
//       name: 'Payshanba',
//       short: 'P',
//     },
//     {
//       name: 'Juma',
//       short: 'J',
//     },
//     {
//       name: 'Shanba',
//       short: 'S',
//       isWeekend: true,
//     },
//     {
//       name: 'Yakshanba',
//       short: 'Y',
//       isWeekend: true,
//     },
//   ],

//   // just play around with this number between 0 and 6
//   weekStartingIndex: 1,

//   // return a { year: number, month: number, day: number } object
//   getToday(gregorainTodayObject) {
//     return gregorainTodayObject;
//   },

//   // return a native JavaScript date here
//   toNativeDate(date) {
//     return new Date(date.year, date.month - 1, date.day);
//   },

//   // return a number for date's month length
//   getMonthLength(date) {
//     return new Date(date.year, date.month, 0).getDate();
//   },

//   // return a transformed digit to your locale
//   transformDigit(digit) {
//     return digit;
//   },

// }
//           locale={myCustomLocale}
const TeachersDate: React.FC<TeachersDateProps> = ({ onChange }) => {
  return (
    <DatePicker
      customDaysClassName={[
        // here we add some CSS classes
        { year: 2024, month: 1, day: 4, className: 'redDay' },
        { year: 2024, month: 1, day: 11, className: 'redDay' },
        { year: 2024, month: 1, day: 18, className: 'redDay' },
        { year: 2024, month: 1, day: 17, className: 'redDay' },
        { year: 2024, month: 1, day: 16, className: 'redDay' },
        { year: 2024, month: 1, day: 15, className: 'redDay' },
        { year: 2024, month: 1, day: 14, className: 'redDay' },
        { year: 2024, month: 1, day: 25, className: 'greenDay' },
        { year: 2024, month: 1, day: 26, className: 'greenDay' },
        { year: 2024, month: 1, day: 27, className: 'greenDay' },
        { year: 2024, month: 1, day: 28, className: 'greenDay' },
        { year: 2024, month: 1, day: 29, className: 'greenDay' },
        { year: 2024, month: 1, day: 30, className: 'greenDay' }
      ]}
    />
  )
}

export default TeachersDate
