import React from 'react'
import Close from '../../../../assets/img/icons/Close'

function MobileMenu() {
  return (
      <div className="mobile-menu">
      <div className="mobile-menu__wrap">
      <div className="mobile-menu__head">
        <a href="#" className="mobile-menu__logo">
          <img src={require('../../../../assets/img/logo.png')} alt="logo" />
        </a>
        <div className="mobile-menu__close">
          <Close/>
        </div>
      </div>
      <div className="mobile-menu__list">
        <li>
          <a href="#">
            <div className="profile-menu__wrap">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.83 8.01002L14.28 2.77002C13 1.75002 11 1.74002 9.72996 2.76002L3.17996 8.01002C2.23996 8.76002 1.66996 10.26 1.86996 11.44L3.12996 18.98C3.41996 20.67 4.98996 22 6.69996 22H17.3C18.99 22 20.59 20.64 20.88 18.97L22.14 11.43C22.32 10.26 21.75 8.76002 20.83 8.01002ZM12.75 18C12.75 18.41 12.41 18.75 12 18.75C11.59 18.75 11.25 18.41 11.25 18V15C11.25 14.59 11.59 14.25 12 14.25C12.41 14.25 12.75 14.59 12.75 15V18Z"
                  fill="currentColor"
                />
              </svg>
              <span>Bosh sahifa</span>
            </div>
          </a>
        </li>
        <li>
          <a href="#">
            <div className="profile-menu__wrap">
              <svg
                width={26}
                height={25}
                viewBox="0 0 26 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="orange"
                  d="M16.0775 7.30888C16.0775 6.62125 15.7388 5.98494 15.1641 5.6052L11.0588 2.86494C10.3712 2.40309 9.46803 2.40309 8.7804 2.86494L4.67514 5.6052C4.11067 5.98494 3.76172 6.62125 3.76172 7.30888V13.1384C3.76172 13.4257 3.98751 13.6515 4.27488 13.6515H15.5644C15.8517 13.6515 16.0775 13.4257 16.0775 13.1384V7.30888ZM9.91961 11.0857C8.93435 11.0857 8.12356 10.2749 8.12356 9.28967C8.12356 8.30441 8.93435 7.49362 9.91961 7.49362C10.9049 7.49362 11.7157 8.30441 11.7157 9.28967C11.7157 10.2749 10.9049 11.0857 9.91961 11.0857Z"
                  fill="currentColor"
                />
                <path
                  d="M23.2628 21.8629H21.9594V18.784C22.9344 18.4658 23.6426 17.5524 23.6426 16.4748V14.4221C23.6426 13.0777 22.5444 11.9795 21.2 11.9795C19.8555 11.9795 18.7573 13.0777 18.7573 14.4221V16.4748C18.7573 17.5421 19.4552 18.4453 20.4097 18.7737V21.8629H16.0786V15.705C16.0786 15.4177 15.8528 15.1919 15.5655 15.1919H4.27601C3.98864 15.1919 3.76285 15.4177 3.76285 15.705V21.8629H2.73653C2.31574 21.8629 1.9668 22.2119 1.9668 22.6326C1.9668 23.0534 2.31574 23.4024 2.73653 23.4024H21.1384C21.1589 23.4024 21.1692 23.4127 21.1897 23.4127C21.2102 23.4127 21.2205 23.4024 21.241 23.4024H23.2628C23.6836 23.4024 24.0326 23.0534 24.0326 22.6326C24.0326 22.2119 23.6836 21.8629 23.2628 21.8629ZM9.15101 18.784C9.15101 18.3632 9.49995 18.0142 9.92074 18.0142C10.3415 18.0142 10.6905 18.3632 10.6905 18.784V21.8629H9.15101V18.784Z"
                  fill="currentColor"
                />
              </svg>
              <span>Maktabim</span>
            </div>
          </a>
        </li>
        <li>
          <a href="#">
            <div className="profile-menu__wrap">
              <svg
                width={26}
                height={26}
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.1565 2.47461H6.84067C5.13698 2.47461 3.76172 3.83961 3.76172 5.52277V16.7199C3.76172 18.403 5.13698 19.768 6.84067 19.768H7.62067C8.44172 19.768 9.22172 20.0862 9.79646 20.6609L11.5515 22.3954C12.352 23.1857 13.6554 23.1857 14.4559 22.3954L16.2109 20.6609C16.7857 20.0862 17.5759 19.768 18.3867 19.768H19.1565C20.8601 19.768 22.2354 18.403 22.2354 16.7199V5.52277C22.2354 3.83961 20.8601 2.47461 19.1565 2.47461ZM11.3359 13.7743C11.7567 13.7743 12.1057 14.1233 12.1057 14.5441C12.1057 14.9649 11.7567 15.3138 11.3359 15.3138H8.5854C8.13382 15.3138 7.71303 15.0983 7.44619 14.7288C7.18961 14.3799 7.12803 13.9488 7.25119 13.5383C7.6104 12.4401 8.49303 11.8449 9.27303 11.3112C10.0941 10.757 10.5559 10.408 10.5559 9.81277C10.5559 9.27908 10.1249 8.84803 9.59119 8.84803C9.05751 8.84803 8.63672 9.28935 8.63672 9.82303C8.63672 10.2438 8.28777 10.5928 7.86698 10.5928C7.44619 10.5928 7.09725 10.2438 7.09725 9.82303C7.09725 8.44777 8.21593 7.31882 9.60146 7.31882C10.987 7.31882 12.1057 8.4375 12.1057 9.82303C12.1057 11.2701 11.0178 12.0091 10.1454 12.6043C9.60146 12.9738 9.0883 13.3228 8.83172 13.7846H11.3359V13.7743V13.7743ZM18.1301 13.8462H17.9146V14.5543C17.9146 14.9751 17.5657 15.3241 17.1449 15.3241C16.7241 15.3241 16.3751 14.9751 16.3751 14.5543V13.8462H14.3533C13.8504 13.8462 13.3886 13.5793 13.132 13.1483C12.8754 12.707 12.8754 12.163 13.132 11.732C13.8299 10.5312 14.6407 9.16619 15.3796 7.97566C15.708 7.45224 16.3341 7.21619 16.9191 7.3804C17.5041 7.55487 17.9146 8.08856 17.9044 8.70435V12.317H18.1301C18.5509 12.317 18.8999 12.6659 18.8999 13.0867C18.8999 13.5075 18.5509 13.8462 18.1301 13.8462Z"
                  fill="currentColor"
                />
                <path
                  d="M16.3761 12.3064V9.28906C15.7706 10.2743 15.1446 11.3314 14.5801 12.2962H16.3761V12.3064Z"
                  fill="currentColor"
                />
              </svg>
              <span>Support</span>
            </div>
          </a>
        </li>
        <li>
          <a href="#">
            <div className="profile-menu__wrap">
              <svg
                width={24}
                height={25}
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.04 2.63184C9.42 2.63184 7.29 4.76184 7.29 7.38184C7.29 9.95184 9.3 12.0318 11.92 12.1218C12 12.1118 12.08 12.1118 12.14 12.1218H12.21C13.4394 12.0808 14.6046 11.5633 15.4593 10.6787C16.314 9.79417 16.7912 8.61188 16.79 7.38184C16.79 4.76184 14.66 2.63184 12.04 2.63184ZM17.12 14.7808C14.33 12.9208 9.78 12.9208 6.97 14.7808C5.7 15.6308 5 16.7808 5 18.0108C5 19.2408 5.7 20.3808 6.96 21.2208C8.36 22.1608 10.2 22.6308 12.04 22.6308C13.88 22.6308 15.72 22.1608 17.12 21.2208C18.38 20.3708 19.08 19.2308 19.08 17.9908C19.07 16.7608 18.38 15.6208 17.12 14.7808Z"
                  fill="currentColor"
                />
              </svg>
              <span>Profile</span>
            </div>
          </a>
        </li>
        <li>
          <a href="lesson-history.html">
            <div className="profile-menu__wrap">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="orange"
                  d="M12.0011 17.3498C12.4334 17.3498 12.848 17.1781 13.1537 16.8724C13.4594 16.5667 13.6311 16.1521 13.6311 15.7198C13.6311 15.2875 13.4594 14.8729 13.1537 14.5673C12.848 14.2616 12.4334 14.0898 12.0011 14.0898C11.5688 14.0898 11.1542 14.2616 10.8485 14.5673C10.5428 14.8729 10.3711 15.2875 10.3711 15.7198C10.3711 16.1521 10.5428 16.5667 10.8485 16.8724C11.1542 17.1781 11.5688 17.3498 12.0011 17.3498Z"
                  fill="currentColor"
                />
                <path
                  d="M16.65 9.44H7.12V8.28C7.12 5.35 7.95 3.4 12 3.4C16.33 3.4 16.88 5.51 16.88 7.35C16.88 7.74 17.19 8.05 17.58 8.05C17.97 8.05 18.28 7.74 18.28 7.35C18.28 3.8 16.17 2 12 2C6.37 2 5.72 5.58 5.72 8.28V9.53C2.92 9.88 2 11.3 2 14.79V16.65C2 20.75 3.25 22 7.35 22H16.65C20.75 22 22 20.75 22 16.65V14.79C22 10.69 20.75 9.44 16.65 9.44ZM12 18.74C10.33 18.74 8.98 17.38 8.98 15.72C8.98 14.05 10.34 12.7 12 12.7C12.8001 12.7026 13.5668 13.0217 14.1326 13.5875C14.6983 14.1532 15.0174 14.9199 15.02 15.72C15.02 17.39 13.67 18.74 12 18.74Z"
                  fill="currentColor"
                />
              </svg>
              <span>Dars tarixi</span>
            </div>
          </a>
        </li>
        <li>
          <a href="teacher-reviews.html">
            <div className="profile-menu__wrap">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 2H7C4.24 2 2 4.23 2 6.98V13.96C2 16.71 4.24 18.94 7 18.94H8.5C8.77 18.94 9.13 19.12 9.3 19.34L10.8 21.33C11.46 22.21 12.54 22.21 13.2 21.33L14.7 19.34C14.89 19.09 15.19 18.94 15.5 18.94H17C19.76 18.94 22 16.71 22 13.96V6.98C22 4.23 19.76 2 17 2ZM8 12C7.44 12 7 11.55 7 11C7 10.45 7.45 10 8 10C8.55 10 9 10.45 9 11C9 11.55 8.56 12 8 12ZM12 12C11.44 12 11 11.55 11 11C11 10.45 11.45 10 12 10C12.55 10 13 10.45 13 11C13 11.55 12.56 12 12 12ZM16 12C15.44 12 15 11.55 15 11C15 10.45 15.45 10 16 10C16.55 10 17 10.45 17 11C17 11.55 16.56 12 16 12Z"
                  fill="currentColor"
                />
              </svg>
              <span>O’qituvchi fikrlari</span>
            </div>
          </a>
        </li>
        <li>
          <a href="calendar.html">
            <div className="profile-menu__wrap">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.7502 3.56V2C16.7502 1.59 16.4102 1.25 16.0002 1.25C15.5902 1.25 15.2502 1.59 15.2502 2V3.5H8.75023V2C8.75023 1.59 8.41023 1.25 8.00023 1.25C7.59023 1.25 7.25023 1.59 7.25023 2V3.56C4.55023 3.81 3.24023 5.42 3.04023 7.81C3.02023 8.1 3.26023 8.34 3.54023 8.34H20.4602C20.7502 8.34 20.9902 8.09 20.9602 7.81C20.7602 5.42 19.4502 3.81 16.7502 3.56ZM19.0002 15C16.7902 15 15.0002 16.79 15.0002 19C15.0002 19.75 15.2102 20.46 15.5802 21.06C15.9328 21.6525 16.4335 22.143 17.0332 22.4831C17.6329 22.8233 18.3108 23.0014 19.0002 23C20.4602 23 21.7302 22.22 22.4202 21.06C22.7902 20.46 23.0002 19.75 23.0002 19C23.0002 16.79 21.2102 15 19.0002 15ZM21.0702 18.57L18.9402 20.54C18.8002 20.67 18.6102 20.74 18.4302 20.74C18.2402 20.74 18.0502 20.67 17.9002 20.52L16.9102 19.53C16.7707 19.3889 16.6925 19.1984 16.6925 19C16.6925 18.8016 16.7707 18.6111 16.9102 18.47C17.2002 18.18 17.6802 18.18 17.9702 18.47L18.4502 18.95L20.0502 17.47C20.3502 17.19 20.8302 17.21 21.1102 17.51C21.3902 17.81 21.3702 18.28 21.0702 18.57Z"
                  fill="currentColor"
                />
                <path
                  d="M20 9.83984H4C3.45 9.83984 3 10.2898 3 10.8398V16.9998C3 19.9998 4.5 21.9998 8 21.9998H12.93C13.62 21.9998 14.1 21.3298 13.88 20.6798C13.68 20.0998 13.51 19.4598 13.51 18.9998C13.51 15.9698 15.98 13.4998 19.01 13.4998C19.3 13.4998 19.59 13.5198 19.87 13.5698C20.47 13.6598 21.01 13.1898 21.01 12.5898V10.8498C21.0074 10.5828 20.9001 10.3274 20.7113 10.1385C20.5224 9.9497 20.2671 9.84245 20 9.83984ZM9.21 18.2098C9.02 18.3898 8.76 18.4998 8.5 18.4998C8.24 18.4998 7.98 18.3898 7.79 18.2098C7.61 18.0198 7.5 17.7598 7.5 17.4998C7.5 17.2398 7.61 16.9798 7.79 16.7898C7.89 16.6998 7.99 16.6298 8.12 16.5798C8.49 16.4198 8.93 16.5098 9.21 16.7898C9.39 16.9798 9.5 17.2398 9.5 17.4998C9.5 17.7598 9.39 18.0198 9.21 18.2098ZM9.21 14.7098L9.06 14.8298C9 14.8698 8.94 14.8998 8.88 14.9198C8.82 14.9498 8.76 14.9698 8.7 14.9798C8.63 14.9898 8.56 14.9998 8.5 14.9998C8.24 14.9998 7.98 14.8898 7.79 14.7098C7.61 14.5198 7.5 14.2598 7.5 13.9998C7.5 13.7398 7.61 13.4798 7.79 13.2898C8.02 13.0598 8.37 12.9498 8.7 13.0198C8.76 13.0298 8.82 13.0498 8.88 13.0798C8.94 13.0998 9 13.1298 9.06 13.1698L9.21 13.2898C9.39 13.4798 9.5 13.7398 9.5 13.9998C9.5 14.2598 9.39 14.5198 9.21 14.7098ZM12.71 14.7098C12.52 14.8898 12.26 14.9998 12 14.9998C11.74 14.9998 11.48 14.8898 11.29 14.7098C11.11 14.5198 11 14.2598 11 13.9998C11 13.7398 11.11 13.4798 11.29 13.2898C11.67 12.9198 12.34 12.9198 12.71 13.2898C12.89 13.4798 13 13.7398 13 13.9998C13 14.2598 12.89 14.5198 12.71 14.7098Z"
                  fill="currentColor"
                />
              </svg>
              <span>Dars jadvali</span>
            </div>
            <span>12</span>
          </a>
        </li>
        <li>
          <a href="#">
            <div className="profile-menu__wrap">
              <svg
                width={25}
                height={25}
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.0346 17.3243H13.7531C14.9333 17.3243 15.8981 16.3596 15.8981 15.1793V14.4609H13.0346V17.3243ZM8.73438 15.1793C8.73438 16.3596 9.69911 17.3243 10.8794 17.3243H11.5978V14.4609H8.73438V15.1793ZM8.73438 12.3159V13.0343H11.5978V10.1709H10.8794C9.69911 10.1709 8.73438 11.1356 8.73438 12.3159Z"
                  fill="currentColor"
                />
                <path
                  d="M20.5572 7.00214L14.6559 2.86609C13.0446 1.73714 10.5814 1.79872 9.03168 2.99951L3.88984 7.0124C2.85326 7.81293 2.05273 9.45504 2.05273 10.7482V17.8298C2.05273 20.4469 4.17721 22.5816 6.78405 22.5816H17.8375C19.0932 22.5789 20.2966 22.0781 21.1836 21.1892C22.0706 20.3003 22.5688 19.0958 22.5688 17.84V10.8816C22.5791 9.49609 21.6862 7.7924 20.5572 7.00214ZM17.3243 15.1819C17.3216 16.131 16.9434 17.0405 16.2722 17.7116C15.6011 18.3828 14.6916 18.761 13.7425 18.7637H10.8791C8.90852 18.7637 7.29721 17.1627 7.29721 15.1819V12.3185C7.29992 11.3693 7.67816 10.4598 8.3493 9.7887C9.02044 9.11756 9.92992 8.73932 10.8791 8.73661H13.7425C15.713 8.73661 17.3243 10.3377 17.3243 12.3185V15.1819Z"
                  fill="currentColor"
                />
                <path
                  d="M13.7516 10.1709H13.0332V13.0343H15.8966V12.3159C15.8966 11.1356 14.9319 10.1709 13.7516 10.1709Z"
                  fill="currentColor"
                />
              </svg>
              <span>Uyga vazifa</span>
            </div>
            <span>24</span>
          </a>
        </li>
        <li>
          <a href="#">
            <div className="profile-menu__wrap">
              <svg
                width={24}
                height={25}
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx={12}
                  cy="12.6318"
                  r={11}
                  fill="#FB9851"
                  fillOpacity="0.1"
                />
                <circle
                  cx={12}
                  cy="12.6318"
                  r={8}
                  fill="#FB9851"
                  fillOpacity="0.3"
                />
                <circle cx={12} cy="12.6318" r={5} fill="#FB9851" />
              </svg>
              <span>Live Lessons</span>
            </div>
            <span>24</span>
          </a>
        </li>
        <li>
          <a href="#">
            <div className="profile-menu__wrap">
              <svg
                width={24}
                height={25}
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.5 16.6318V19.1318C20.5 21.0618 18.93 22.6318 17 22.6318H7C5.07 22.6318 3.5 21.0618 3.5 19.1318V18.4818C3.5 16.9118 4.78 15.6318 6.35 15.6318H19.5C20.05 15.6318 20.5 16.0818 20.5 16.6318ZM15.5 2.63184H8.5C4.5 2.63184 3.5 3.63184 3.5 7.63184V15.2118C4.26 14.5418 5.26 14.1318 6.35 14.1318H19.5C20.05 14.1318 20.5 13.6818 20.5 13.1318V7.63184C20.5 3.63184 19.5 2.63184 15.5 2.63184ZM13 11.3818H8C7.59 11.3818 7.25 11.0418 7.25 10.6318C7.25 10.2218 7.59 9.88184 8 9.88184H13C13.41 9.88184 13.75 10.2218 13.75 10.6318C13.75 11.0418 13.41 11.3818 13 11.3818ZM16 7.88184H8C7.59 7.88184 7.25 7.54184 7.25 7.13184C7.25 6.72184 7.59 6.38184 8 6.38184H16C16.41 6.38184 16.75 6.72184 16.75 7.13184C16.75 7.54184 16.41 7.88184 16 7.88184Z"
                  fill="currentColor"
                />
              </svg>
              <span>Kutubona</span>
            </div>
          </a>
        </li>
      </div>
    </div>
  </div>
  )
}

export default MobileMenu