import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import Form from '../../../../components/form'
import CustomInput from '../../../../components/form/custom-input'

const validationSchema = Yup.object().shape({
  phone: Yup.string().required('This field is required')
})
const initialValues = {
  phone: ''
}

interface IProps {
  setToken: React.Dispatch<React.SetStateAction<string>>
  setPhone: React.Dispatch<React.SetStateAction<string>>
  setStep: React.Dispatch<React.SetStateAction<number>>
}

export const SendPhone = (props: IProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      {/*  LOGIN	*/}
      <section className='login'>
        <div className='login__wrap'>
          <div className='login__logo'>
            <img src={require('../../../../assets/img/logo.png')} alt='Onschool' />
          </div>
          <Form
            url='forgot_password/send_code'
            initialValues={initialValues}
            validationSchema={validationSchema}
            method='post'
            onSuccess={data => {
              props.setToken(data?.data?.token)
              props.setStep(2)
            }}
          >
            {({ form, handleFinish }) => {
              const {
                control,
                formState: { errors },
                handleSubmit
              } = form
              return (
                <div className='login-form login-form__centered'>
                  <div className='login-form__title'>{t('Forgot password')}</div>
                  <CustomInput
                    control={control}
                    name='phone'
                    label='Phone'
                    placeholder='Write phone'
                    error={errors?.phone}
                  />
                  <div className='login-form__submit'>
                    <button
                      onClick={handleSubmit(data => {
                        handleFinish(data)
                        props.setPhone(data?.phone)
                      })}
                      className='btn btn-orange'
                    >
                      {t('Submit')}
                    </button>
                  </div>
                </div>
              )
            }}
          </Form>
        </div>
      </section>
    </>
  )
}
