import axios from 'axios'
import { toast } from 'react-hot-toast'
import i18n from './i18n'

const production = false

export const API_URL = production ? process.env.REACT_APP_API_URL || '' : 'https://uniclass.uz/api/v1/'
export const MEDIA_URL = process.env.REACT_APP_MEDIA_URL || 'https://uniclass.uz'
export const SITE_URL = process.env.REACT_APP_SITE_URL || 'https://tezparcel.co.uk/'
export const WS_URL = production ? process.env.REACT_APP_WS_URL || '' : 'https://uniclass.uz/api/v1/'

const client = axios.create({ baseURL: API_URL })
const clientFile = axios.create({ baseURL: MEDIA_URL })
export const request = ({ ...options }) => {
  const auth = window.localStorage.getItem('accessToken') ? `Bearer ${window.localStorage.getItem('accessToken')}` : ''
  client.defaults.headers.common.Authorization = auth
  client.defaults.headers.common.Accept = 'multipart/form-data'
  client.defaults.headers['Accept-language'] = i18n.language

  const onSuccess = (response: any) => {
    return response
  }
  const onError = (error: any) => {
    toast.error(`${error.message}, ${options.url}`)

    return Promise.reject(error)
  }

  return client(options).then(onSuccess).catch(onError)
}

export const requestFIle = ({ ...options }) => {
  const auth = window.localStorage.getItem('accessToken') ? `Bearer ${window.localStorage.getItem('accessToken')}` : ''
  clientFile.defaults.headers.common.Authorization = auth
  clientFile.defaults.headers.common.Accept = 'multipart/form-data'
  clientFile.defaults.headers['Accept-language'] = i18n.language

  const onSuccess = (response: any) => {
    return response
  }
  const onError = (error: any) => {
    toast.error(`${error.message}, ${options.url}`)

    return Promise.reject(error)
  }

  return clientFile(options).then(onSuccess).catch(onError)
}

export const requestNoAuth = ({ ...options }) => {
  client.defaults.headers.common.Accept = 'multipart/form-data'
  client.defaults.headers['Accept-language'] = i18n.language
  client.defaults.headers['Access-Control-Allow-Origin'] = '*'

  const onSuccess = (response: any) => {
    return response
  }
  const onError = (error: any) => {
    toast.error(`${error.message}, ${options.url}`)

    return Promise.reject(error)
  }

  return client(options).then(onSuccess).catch(onError)
}
