import CalendarFill from '../../../assets/img/icons/CalendarFill'
import Timer from '../../../assets/img/icons/Timer'

export const TeacherHome = () => (
  <div className='calendar-main'>
    <div className='calendar-search'>
      <div className='calendar-input'>
        <input type='text' placeholder='...dan' />
        <CalendarFill />
      </div>
      <div className='calendar-input'>
        <input type='text' placeholder='...gacha' />
        <CalendarFill />
      </div>
      <div className='calendar-input'>
        <input type='text' placeholder='Vaqtni tanlash' />
        <Timer />
      </div>
      <button className='calendar-btn btn'>Jadvalga qo’shish</button>
      <button className='calendar-btn btn btn-green'>Saqlash</button>
    </div>
    <div className='calendar-head'>
      <div className='calendar-title'>Schedule</div>
      <div className='calendar-head__wrap'>
        <div className='calendar-head__date'>Noyabr 2022</div>
        <div className='calendar-head__today'>Bugun</div>
        <div className='calendar-head__arrows'>
          <div className='arrows'>
            <span className='arrow-left'>
              <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M14 8L10 12L14 16'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </span>
            <span className='arrow-right'>
              <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M10 16L14 12L10 8'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
)
