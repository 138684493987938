export const Notifications = () => (
  <div className='messages-wrap'>
    <ul className='messages-nots'>
      <li className='messages-nots__item new'>
        <div className='messages-nots__wrap'>
          <div className='messages-nots__name'>Hi Designer,</div>
          <div className='messages-nots__text'>
            Ever have a project idea you want to document before you forget it? A task you need to assign on the go?
          </div>
          <div className='messages-nots__time'>
            <span>Yanuar 10,2023</span>
            <span>17:20</span>
          </div>
        </div>
        <div className='messages-nots__img'>
          <img src={require('../../../assets/img/mock/notification.jpg')} alt='Notification' />
        </div>
      </li>
      <li className='messages-nots__item new'>
        <div className='messages-nots__wrap'>
          <div className='messages-nots__name'>Hi Designer,</div>
          <div className='messages-nots__text'>
            Ever have a project idea you want to document before you forget it? A task you need to assign on the go?
          </div>
          <div className='messages-nots__time'>
            <span>Yanuar 10,2023</span>
            <span>17:20</span>
          </div>
        </div>
      </li>
      <li className='messages-nots__item'>
        <div className='messages-nots__wrap'>
          <div className='messages-nots__name'>Hi Designer,</div>
          <div className='messages-nots__text'>
            Ever have a project idea you want to document before you forget it? A task you need to assign on the go?
          </div>
          <div className='messages-nots__time'>
            <span>Yanuar 10,2023</span>
            <span>17:20</span>
          </div>
        </div>
      </li>
      <li className='messages-nots__item'>
        <div className='messages-nots__wrap'>
          <div className='messages-nots__name'>Hi Designer,</div>
          <div className='messages-nots__text'>
            Ever have a project idea you want to document before you forget it? A task you need to assign on the go?
          </div>
          <div className='messages-nots__time'>
            <span>Yanuar 10,2023</span>
            <span>17:20</span>
          </div>
        </div>
      </li>
      <li className='messages-nots__item'>
        <div className='messages-nots__wrap'>
          <div className='messages-nots__name'>Hi Designer,</div>
          <div className='messages-nots__text'>
            Ever have a project idea you want to document before you forget it? A task you need to assign on the go?
          </div>
          <div className='messages-nots__time'>
            <span>Yanuar 10,2023</span>
            <span>17:20</span>
          </div>
        </div>
      </li>
      <li className='messages-nots__item'>
        <div className='messages-nots__wrap'>
          <div className='messages-nots__name'>Hi Designer,</div>
          <div className='messages-nots__text'>
            Ever have a project idea you want to document before you forget it? A task you need to assign on the go?
          </div>
          <div className='messages-nots__time'>
            <span>Yanuar 10,2023</span>
            <span>17:20</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
)
