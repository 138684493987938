import MyCalendar from './component/bigCalendar'
import handlePrevMonth from './component/bigCalendar/'
import handleNextMonth from './component/bigCalendar/'
export const Calendar = () => (
  <>
    <div className='courses-waiting'>
      <div className='calendar-title'>Kutilyotgan kurslar</div>
      <div className='history-table'>
        <div className='history-table__head'>
          <div>Date/ Time</div>
          <div>Lesson</div>
          <div>Teacher</div>
          <div>Work</div>
          <div></div>
        </div>
        <div className='history-table__row'>
          <div className='history-table__date'>
            14:20 22.12.10
            <span>11:30-11:55+05</span>
            <small>+3</small>
          </div>
          <div className='course-type'>
            <span className='course-type__ico'>
              <svg width={18} height={18} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M6.375 7.87477H11.625M5.25 13.8223H8.25L11.5875 16.0423C11.7001 16.1173 11.831 16.1604 11.9662 16.167C12.1015 16.1735 12.2359 16.1432 12.3552 16.0793C12.4746 16.0154 12.5743 15.9203 12.6438 15.8041C12.7134 15.688 12.7501 15.5551 12.75 15.4198V13.8223C15 13.8223 16.5 12.3223 16.5 10.0723V5.57227C16.5 3.32227 15 1.82227 12.75 1.82227H5.25C3 1.82227 1.5 3.32227 1.5 5.57227V10.0723C1.5 12.3223 3 13.8223 5.25 13.8223V13.8223Z'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeMiterlimit={10}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </span>
            <span>
              Speaking Course
              <p>3-9-1 Today,Maybe</p>
            </span>
          </div>
          <div className='history-table__teacher'>
            <img src={require('../../../assets/img/mock/student.jpg')} alt='name' />
            <span>Nicole Cilliers</span>
          </div>
          <div className='history-table__work'>Rich Lesson 1</div>
          <div className='history-table__waiting'>Kutilmoqda</div>
          <a href='#' className='history-table__link' />
        </div>
      </div>
    </div>
    <div className='courses-today'>
      <div className='calendar-title'>Bugungi kurslar</div>
      <div className='history-table'>
        <div className='history-table__head'>
          <div></div>
          <div>Date/ Time</div>
          <div>Lesson</div>
          <div>Type</div>
          <div>Teacher</div>
          <div></div>
        </div>
        <div className='history-table__row'>
          <div className='history-table__count'>
            <div>-4:21</div>
          </div>
          <div className='history-table__date'>
            14:20 22.12.10
            <span>11:30-11:55+05</span>
          </div>
          <div className='course-type'>
            <span className='course-type__ico'>
              <svg width={18} height={18} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M6.375 7.87477H11.625M5.25 13.8223H8.25L11.5875 16.0423C11.7001 16.1173 11.831 16.1604 11.9662 16.167C12.1015 16.1735 12.2359 16.1432 12.3552 16.0793C12.4746 16.0154 12.5743 15.9203 12.6438 15.8041C12.7134 15.688 12.7501 15.5551 12.75 15.4198V13.8223C15 13.8223 16.5 12.3223 16.5 10.0723V5.57227C16.5 3.32227 15 1.82227 12.75 1.82227H5.25C3 1.82227 1.5 3.32227 1.5 5.57227V10.0723C1.5 12.3223 3 13.8223 5.25 13.8223V13.8223Z'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeMiterlimit={10}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </span>
            <span>
              Speaking Course
              <p>3-9-1 Today,Maybe</p>
            </span>
          </div>
          <div className='history-table__type'>Private/ Tutoring</div>
          <div className='history-table__teacher'>
            <span>Nicole Cilliers</span>
          </div>
          <div className='history-table__btns'>
            <button className='btn btn-transparent'>Cancel</button>
            <button className='btn'>Go to class</button>
          </div>
        </div>
      </div>
    </div>
    <MyCalendar />
  </>
)
