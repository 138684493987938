import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { MEDIA_URL } from '../../../../configs/request'
import { ILiveLesson } from '../../../../types/types'

interface IProps {
  item: ILiveLesson
}

function LiveLessonCard(props: IProps) {
  const { t, i18n } = useTranslation()
  return (
    <li className='live-item'>
      <div className='live-item__img'>
        <img src={MEDIA_URL + props.item?.photo} alt='course1' />
      </div>
      <div className='live-item__wrap'>
        <div className='live-item__main'>
          <div className='live-item__head'>
            <div className='live-item__cat'>Yoga Time</div>
            <ul className='live-item__tags'>
              <li>{t('Free')}</li>
            </ul>
          </div>
          <div className='live-item__name'>{props.item?.title?.[i18n.language]}</div>
          <div
            className='live-item__desc'
            dangerouslySetInnerHTML={{ __html: props.item?.description?.[i18n.language] }}
          ></div>
        </div>
        <Link to={props.item?.link} target='_blank' className='live-item__btn btn btn-lightblue'>
          {props.item?.button_name?.[i18n.language]}
        </Link>
      </div>
    </li>
  )
}

export default LiveLessonCard
