import { TaklifSlider } from './components/taklifSlider'
import { YaqinSlider } from './components/yaqinSlider'
export const Home = () => (
  <>
    <YaqinSlider onClick={() => {}} />

    <div className='home-welcome'>
      <div className='home-welcome__content'>
        <div className='home-welcome__title'>
          Xush kelibsiz <strong>Madina!</strong>
        </div>
        <div className='home-welcome__text'>This Challenge Describes The Class Placement, Contents Of Each Course</div>
        <div className='home-welcome__links'>
          <a href='/school/courses' className='blue'>
            Kurslar ro’yxati
          </a>
          <a href='#'>Keyinga qoldirish</a>
        </div>
      </div>
      <div className='home-welcome__img'>
        <img src={require('../../../assets/img/welcome.jpg')} alt='welcome' />
      </div>
    </div>

    <TaklifSlider onClick={() => {}} />

    {/* <div className='lesson-enter'>
      <div className='lesson-enter__content'>
        <div className='lesson-enter__name'>
          This Challenge Describes Required System Status For Taking Our Online Lesson.
        </div>
        <div className='lesson-enter__date'>Jan 22, 2023 (Sun) 9:30 -09:55 +05</div>
        <div className='lesson-enter__advice'>
          Diqqat! Dars boshlanishiga 24 soatdan kam vaqt qolganda, bekor qilingan dars tangasi qaytarilmaydi. / dars
          tangasining 50% (yarmi) qaytariladi.
        </div>
        <div className='lesson-enter__text'>
          Savollaringiz bo’lsa talabalarni qo’llab qo’vatlash jamoamiz bilan bo’g’laning:
        </div>
        <div className='lesson-enter__text'>
          Tel:<a href='#'> 050-1791 -0037</a> Hours: 10:00 - 18:0 JST Weekdays
          <br />
          E.pochta: <a href='mailto:gsainfo@gsacademy.com'>gsainfo@gsacademy.com</a>
        </div>
        <div className='lesson-enter__btns'>
          <a href='#' className='btn btn-gray'>
            Qatnashmaslik
          </a>
          <a href='lessonLink' className='btn'>
            Darsga kirish
          </a>
        </div>
      </div>
    </div> */}
  </>
)
