import React, { ReactElement, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { AuthContext } from '../../../../context/AuthContext'

export interface IProfileMenuItem {
  icon: ReactElement
  title: string
  path: string
  count?: number
  parent?: string
}

function ProfileMenuItem(props: IProfileMenuItem) {
  const { user }: any = useContext(AuthContext)
  return (
    <li>
      <NavLink
        to={user?.role === 'student' ? `/student/${props.parent}` + props.path : '/teacher' + props.path}
        className={({ isActive, isPending }) => (isPending ? 'pending' : isActive ? 'current' : '')}
      >
        <div className='profile-menu__wrap'>
          {props.icon}
          <span>{props.title}</span>
        </div>
        <span>{props.count}</span>
      </NavLink>
    </li>
  )
}

export default ProfileMenuItem
