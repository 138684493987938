export const Messages = () => (
  <div className='messages-wrap'>
    <ul className='messages-list'>
      <li>
        "Hi Laziza, good job completing today's lesson! You are doing great! Good job expressing about yourself during
        the free-talk as well! Keep up the good work and see you in the next class! Teacher Hassna 💖"
      </li>
      <li>
        "🙋Hello Laziza🙋 It was great speaking with you again !!!⛄ In was nice hearing about school and your day. We
        read your last answers well adn then today we spoke about the weather in your country / city and how it can
        effect the roads 💌Great work as always !!! Thank you for the amazing answers. 📘No homework but you can read
        today's questions if you want. 😇I'll see you again next time , thank you Laziza
        Link:📎https://docs.google.com/document/d/1qF2tdtcnexgFsaKEEh-HxkL0e2RehSsWHa2nZTfYPF8/edit?usp=sharing📎"
      </li>
    </ul>
    <div className='messages-form'>
      <input type='text' placeholder='Xabar' />
      <button className='btn'>Yuborish</button>
    </div>
  </div>
)
