import React, { ReactNode } from 'react'
import QueryProvider from './QueryProvider'
import { AuthProvider } from '../context/AuthContext'

interface IProps {
  children: ReactNode
}
const Providers = (props: IProps) => {
  return (
    <AuthProvider>
      <QueryProvider>{props.children}</QueryProvider>
    </AuthProvider>
  )
}

export default Providers
