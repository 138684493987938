export const TeacherPayment = () => (
  <div className='payment-info'>
    <div className='payment-info__head'>
      <div className='payment-info__wrap'>
        <div className='payment-info__title'>Summary</div>
        <div className='payment-info__btn btn btn-green'>Class history</div>
      </div>
      <div className='payment-info__wrap'></div>
    </div>
    <div className='history-table'>
      <div className='history-table__head'>
        <div>Program</div>
        <div>Completed</div>
        <div>Incomplete</div>
        <div>Screening</div>
        <div>No Show</div>
        <div>Replaced</div>
        <div>Replaced 24h</div>
        <div>Partial</div>
        <div>Extra Hours</div>
        <div>Sub Bonus</div>
      </div>
      <div className='history-table__row'>
        <div className='history-table__item'>ELA Advanced</div>
        <div className='history-table__item'>0.0h(0class)</div>
        <div className='history-table__item'>0.0h(0class)</div>
        <div className='history-table__item'>0.0h(0class)</div>
        <div className='history-table__item'>0.0h(0class)</div>
        <div className='history-table__item'>0.0h(0class)</div>
        <div className='history-table__item'>0.0h(0class)</div>
        <div className='history-table__item'>0.0h(0class)</div>
        <div className='history-table__item'>0.0h</div>
        <div className='history-table__item'>0.0h(0class)</div>
      </div>
    </div>
  </div>
)
