import GetContainer from '../../../components/get-container'
import { ICoursePart } from '../../../types/types'
import GroupCourseCard from './components/GroupCourseCard'

export const GroupCourses = () => {
  return (
    <div className='courses-group'>
      <div className='courses-group__filter'>
        <div className='courses-group__wrap'>
          <div className='courses-group__title'>Guruh darslarini ko’rish</div>
          <div className='search'>
            <div className='search-input'>
              <input type='text' className='search-input__input' placeholder='Darslarni izlash ...' />
              <svg
                width={20}
                height={20}
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className='search-input__filter'
              >
                <path
                  d='M15.8333 18.3337V9.16699M15.8333 5.83366V1.66699M10 18.3337V14.167M10 10.8337V1.66699M4.16667 18.3337V9.16699M4.16667 5.83366V1.66699M2.5 9.16699H5.83333M14.1667 9.16699H17.5M8.33333 10.8337H11.6667'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeMiterlimit={10}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <svg
                width={20}
                height={20}
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className='search-input__ico'
              >
                <path
                  d='M18.3327 18.3337L16.666 16.667M9.58268 17.5003C10.6223 17.5003 11.6518 17.2956 12.6123 16.8977C13.5728 16.4999 14.4455 15.9167 15.1806 15.1816C15.9157 14.4465 16.4989 13.5737 16.8967 12.6132C17.2946 11.6527 17.4993 10.6233 17.4993 9.58366C17.4993 8.54403 17.2946 7.51458 16.8967 6.55408C16.4989 5.59359 15.9157 4.72086 15.1806 3.98573C14.4455 3.2506 13.5728 2.66746 12.6123 2.26961C11.6518 1.87176 10.6223 1.66699 9.58268 1.66699C7.48305 1.66699 5.46942 2.50107 3.98475 3.98573C2.50009 5.47039 1.66602 7.48403 1.66602 9.58366C1.66602 11.6833 2.50009 13.6969 3.98475 15.1816C5.46942 16.6662 7.48305 17.5003 9.58268 17.5003V17.5003Z'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </div>
        </div>
        <div className='courses-group__wrap'>
          <div className='courses-group__subtitle'>Sort by</div>
          <div className='dropdown'>
            <div className='dropdown__btn'>Boshlanish sanasi</div>
            {/* <ul class="dropdown__list">
							<li>
								some
							</li>
							<li>
								some
							</li>
							<li>
								some
							</li>
						</ul> */}
          </div>
        </div>
      </div>
      <ul className='courses-group__list'>
        <GetContainer url='student/course_parts'>
          {({ data }) => data?.data?.map((item: ICoursePart) => <GroupCourseCard item={item} key={item.slug} />)}
        </GetContainer>
      </ul>
    </div>
  )
}
