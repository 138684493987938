export const LessonsHistorySingle = () => (
  <div className='history-single'>
    <div className='history-single__name'>2-5-27 Sir Gus: ‘’Battle Plans”</div>
    <div className='history-single__item'>
      <div className='history-single__title'>1 Greeting &amp; Homework Review</div>
      <div className='history-single__text'>
        <textarea defaultValue={''} />
      </div>
    </div>
    <div className='history-single__item big'>
      <div className='history-single__title'>2 Greeting &amp; Homework Review</div>
      <div className='history-single__text'>
        <textarea defaultValue={''} />
      </div>
    </div>
  </div>
)
