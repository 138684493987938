//@ts-check
import React, {useState} from 'react'


const data = [
    {
      title: '3-3-8 Level 5 Lesson 6..',
      time: '11:30-11:55+05',
      answer:' Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure nam eligendi assumenda iusto tenetur mollitia quo distinctio doloremque at iste odit aspernatur adipisci, error placeat id quisquam temporibus ullam officiis?',
      typeLesson: 'Individual dars',
      teacher: 'Samatha Blake',
    },
  ]


const MoreLesson = ({event,isHidden} : any) => {


  return (
    <div>
        {
            data.map((item,i) => (
                <div className="moreLessonItem-title">
                <div className="lessonName">{item.title}</div>
                <div className="lessonTime">{item.time}</div>
            </div>
            ))
        }
    </div>

  )
}

export default MoreLesson


