export const HomeWork = () => (
  <div className='homework-main'>
    <div className='homework-head'>
      <div className='homework__title'>/er/ sound, Quotation Mark, Review Spelling /ou/</div>
      <a href='#' className='homework__link'>
        View Lesson
      </a>
    </div>
    <ul className='homework-info'>
      <li>
        GRADE: <span>2</span>
      </li>
      <li>
        UNIT: <span>2</span>
      </li>
      <li>
        LESSON: <span>14</span>
      </li>
    </ul>
    <div className='homework-content'>
      <div className='homework-content__title'>1 Recap and Homework</div>
      <div className='homework-content__text'>
        Copy the sentences to a piece of paper. Find and circle the word with the ‘er’ spelling in each sentence. Write
        the word and circle the ‘er’ spelling. Read the sentences to a family member.
      </div>
      <div className='homework-content__img'>
        <img src={require('../../../assets/img/mock/homework.png')} alt='homework' />
      </div>
      <ul className='homework-content__list'>
        <li>Who is that person?</li>
        <li>Who is that person?</li>
        <li>Who is that person?</li>
        <li>Who is that person?</li>
        <li>Who is that person?</li>
        <li>Who is that person?</li>
        <li>Who is that person?</li>
      </ul>
      <a href='#' className='homework-content__download'>
        <span>Download Homework</span>
        <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M9 11V17L11 15M9 17L7 15'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M22 10H18C15 10 14 9 14 6V2L22 10Z'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </a>
    </div>
    <div className='homework-upload'>
      <div>Drag your file</div>
      <label htmlFor='file'>
        <span>Browse</span>
        <input type='file' id='file' />
      </label>
      <button className='btn'>Submit Homework</button>
    </div>
  </div>
)
