import Copy from '../../../assets/img/icons/Copy'

export const Settings = () => (
  <div className='settings-form'>
    <div className='settings-form__item'>
      <div className='settings-title'>Shaxsiy ma’lumot</div>
      <div className='settings-wrap'>
        <div className='settings-item'>
          <div className='settings-item__name'>Ism</div>
          <input type='text' className='settings-item__input' />
        </div>
        <div className='settings-item'>
          <div className='settings-item__name'>Sharifi</div>
          <input type='text' className='settings-item__input' />
        </div>
      </div>
      <div className='settings-wrap'>
        <div className='settings-item'>
          <div className='settings-item__name'>Elektron manzil</div>
          <input type='email' className='settings-item__input' />
        </div>
        <div className='settings-item'>
          <div className='settings-item__name'>Tel. raqami</div>
          <input type='tel' className='settings-item__input' />
        </div>
      </div>
      <div className='settings-wrap'>
        <div className='settings-item'>
          <div className='settings-item__name'>Postal code / zip code</div>
          <input type='text' className='settings-item__input' />
        </div>
        <div className='settings-item'></div>
      </div>
      <div className='settings-wrap'>
        <div className='settings-item'>
          <div className='settings-item__name'>Manzil</div>
          <input type='text' className='settings-item__input' />
        </div>
      </div>
      <div className='settings-wrap'>
        <div className='settings-item'>
          <div className='settings-item__name'>Tug’ilgan sana</div>
          <input type='text' className='settings-item__input' />
        </div>
        <div className='settings-item'>
          <div className='settings-item__name'>Jinsi</div>
          <div className='filter-gender'>
            <label htmlFor='male'>
              <input type='radio' name='gender' id='male' />
              <span className='btn btn-white'>Erkak</span>
            </label>
            <label htmlFor='female'>
              <input type='radio' name='gender' id='female' />
              <span className='btn btn-white'>Ayol</span>
            </label>
          </div>
        </div>
      </div>
      <div className='settings-wrap'>
        <div className='settings-item'>
          <div className='settings-item__name'>Vaqt zonasi</div>
          <input type='text' className='settings-item__input' />
        </div>
        <div className='settings-item'>
          <div className='settings-item__name'>Eslatma</div>
          <div className='filter-gender'>
            <label htmlFor='yes'>
              <input type='radio' name='reminder' id='yes' />
              <span className='btn btn-white'>Qabo’l qilaman</span>
            </label>
            <label htmlFor='no'>
              <input type='radio' name='reminder' id='no' />
              <span className='btn btn-white'>Qabo’l qilmayman</span>
            </label>
          </div>
        </div>
      </div>
      <button className='settings-btn btn btn-orange'>Saqlash</button>
    </div>
    <div className='settings-form__item settings-form__item-full'>
      <div className='settings-title'>Parolni yangilash</div>
      <div className='settings-wrap'>
        <div className='settings-item'>
          <div className='settings-item__name'>Xozirgi parol</div>
          <input type='password' className='settings-item__input' />
        </div>
        <div className='settings-item'>
          <div className='settings-item__name'>Yangi parol</div>
          <input type='password' className='settings-item__input' />
        </div>
        <div className='settings-item'>
          <div className='settings-item__name'>Yangi parol (tasdiqlash uchun)</div>
          <input type='password' className='settings-item__input' />
        </div>
      </div>
      <button className='settings-btn btn btn-orange'>Yangi parolni saqlash</button>
    </div>
    <div className='settings-form__item settings-form__item-full'>
      <div className='settings-title'>Referal Kod</div>
      <div className='settings-wrap'>
        <div className='settings-item'></div>
        <div className='settings-item'>
          <div className='settings-item__copy'>
            <input type='password' className='settings-item__input' />
            <Copy />
          </div>
          <div className='settings-item__text'>
            Ushbu kod ordali do’stingizni maktabimizga taklif qilganingizda siz va do’stingiz, ikkingiz ham qo’shimcha
            tangalarga ega bo’lasiz. Bu ajoyib imkoniyatni qo’ldan boy bermang!
          </div>
        </div>
        <div className='settings-item'></div>
      </div>
    </div>
  </div>
)
