import { useContext, useEffect } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { toast } from 'react-hot-toast'
import { UseFormReturn, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { request } from '../../configs/request'

export interface IChildrenProps {
  createInfo: any
  getInfo: any
  handleFinish: (values: any, type?: string) => void
  form: UseFormReturn
}
export interface ICollectionForm {
  children: (props: IChildrenProps) => JSX.Element
  errorMessage?: string
  initialValues?: any
  url: string
  getUrl?: string
  validationSchema: any
  isEdit?: boolean
  id?: number
  method: string
  onSuccess?: (data: any) => void
  onError?: (error: any) => void
}

export default function Form(props: ICollectionForm) {
  const form = useForm({ defaultValues: props.initialValues, resolver: yupResolver(props.validationSchema) })
  const {
    reset,
    formState: { errors }
  } = form

  const getInfo = useQuery({
    queryKey: [props.getUrl, props.isEdit],
    queryFn: async () => {
      const response = await request({
        url: props.getUrl,
        method: 'GET'
      })

      return response.data
    },
    enabled: props.isEdit || !!props.getUrl
  })

  useEffect(() => {
    if (getInfo.isSuccess) {
      if (props.isEdit) {
        reset(getInfo?.data?.data)
      } else {
        reset(props.initialValues)
      }
    }
  }, [getInfo.isSuccess])

  const { refetch: get } = getInfo

  const createInfo = useMutation({
    mutationFn: async (data: any) => {
      const response = await request({
        url: props.url,
        data: data,
        method: props.method
      })
      return response.data
    },
    onSuccess(data) {
      console.log(data, 'data')

      if (!!props.onSuccess) {
        props.onSuccess(data)
      }
    },
    onError(error) {
      toast.error(props.errorMessage || String(error))
      if (props.onError) {
        props.onError(error)
      }
    }
  })
  const { mutate: create } = createInfo

  async function handleFinish(values: any) {
    create({ ...values })
  }

  console.log(errors, 'errors from form')

  return props.children({
    createInfo,
    getInfo,
    handleFinish,
    form
  })
}
