export const NewCourses = () => (
  <div className='courses-new'>
    <div className='courses-new__title'>Yangi kurs olish uchun murojat yuborish</div>
    <div className='courses-new__text'>
      At On School Academy, we offer different courses depending on the age and English level of the student. Please
      check the form below before applying. In case your child has not yet reached the required English level or age,
      you can negotiate for potential adjustments by contacting our Student Management Department
    </div>
    <div className='courses-new__subtitle'>Course Overview (based on level and age) (1)</div>
    <div className='table courses-new__table'>
      <table>
        <tbody>
          <tr className='head'>
            <td>Cource</td>
            <td>Minimum age</td>
            <td>level</td>
            <td>Division Accumulated English exposure Time</td>
            <td>Coins</td>
          </tr>
          <tr>
            <td>Phonics Bootcamp</td>
            <td>6 years old</td>
            <td>ELA Standart </td>
            <td>Rooke Division / ) hours and up</td>
            <td>100</td>
          </tr>
          <tr>
            <td>Phonics Bootcamp</td>
            <td>6 years old</td>
            <td>ELA Standart </td>
            <td>Rooke Division / ) hours and up</td>
            <td>100</td>
          </tr>
          <tr>
            <td>Phonics Bootcamp</td>
            <td>6 years old</td>
            <td>ELA Standart </td>
            <td>Rooke Division / ) hours and up</td>
            <td>100</td>
          </tr>
          <tr>
            <td>Phonics Bootcamp</td>
            <td>6 years old</td>
            <td>ELA Standart </td>
            <td>Rooke Division / ) hours and up</td>
            <td>100</td>
          </tr>
          <tr>
            <td>Phonics Bootcamp</td>
            <td>6 years old</td>
            <td>ELA Standart </td>
            <td>Rooke Division / ) hours and up</td>
            <td>100</td>
          </tr>
          <tr>
            <td>Phonics Bootcamp</td>
            <td>6 years old</td>
            <td>ELA Standart </td>
            <td>Rooke Division / ) hours and up</td>
            <td>100</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className='courses-new__subtitle'>Course Overview (based on level and age) (2)</div>
    <div className='table courses-new__table'>
      <table>
        <tbody>
          <tr className='head'>
            <td>Cource</td>
            <td>Minimum age</td>
            <td>level</td>
            <td>Division Accumulated English exposure Time</td>
            <td>Coins</td>
          </tr>
          <tr>
            <td>Phonics Bootcamp</td>
            <td>6 years old</td>
            <td>ELA Standart </td>
            <td>Rooke Division / ) hours and up</td>
            <td>100</td>
          </tr>
          <tr>
            <td>Phonics Bootcamp</td>
            <td>6 years old</td>
            <td>ELA Standart </td>
            <td>Rooke Division / ) hours and up</td>
            <td>100</td>
          </tr>
          <tr>
            <td>Phonics Bootcamp</td>
            <td>6 years old</td>
            <td>ELA Standart </td>
            <td>Rooke Division / ) hours and up</td>
            <td>100</td>
          </tr>
          <tr>
            <td>Phonics Bootcamp</td>
            <td>6 years old</td>
            <td>ELA Standart </td>
            <td>Rooke Division / ) hours and up</td>
            <td>100</td>
          </tr>
          <tr>
            <td>Phonics Bootcamp</td>
            <td>6 years old</td>
            <td>ELA Standart </td>
            <td>Rooke Division / ) hours and up</td>
            <td>100</td>
          </tr>
          <tr>
            <td>Phonics Bootcamp</td>
            <td>6 years old</td>
            <td>ELA Standart </td>
            <td>Rooke Division / ) hours and up</td>
            <td>100</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className='courses-new__subtitle'>Siz xohlayotgan kurs *</div>
    <form className='courses-new__form'>
      <div className='courses-new__checks'>
        <label htmlFor='th1'>
          <input type='checkbox' name='courses' id='th1' />
          <span>Math Standard</span>
        </label>
        <label htmlFor='fr2'>
          <input type='checkbox' name='courses' id='fr2' />
          <span>Phonics Bootcamp</span>
        </label>
        <label htmlFor='sa3'>
          <input type='checkbox' name='courses' id='sa3' />
          <span>Story Time</span>
        </label>
      </div>
      <div className='courses-new__buttons'>
        <button className='btn btn-orange'>Jo’natish</button>
        <button className='btn btn-transparent'>ro’yxatni tozalash</button>
      </div>
    </form>
  </div>
)
