import * as React from 'react'
import { SVGProps } from 'react'
const CalendarWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} fill='none' {...props}>
    <path
      fill='#fff'
      d='M14.25 11.25a3 3 0 0 0-3 3c0 .563.158 1.095.435 1.545a2.977 2.977 0 0 0 2.565 1.455 2.977 2.977 0 0 0 2.565-1.455 2.94 2.94 0 0 0 .435-1.545 3 3 0 0 0-3-3Zm1.553 2.678-1.598 1.477a.572.572 0 0 1-.383.15.556.556 0 0 1-.397-.165l-.743-.742a.566.566 0 0 1 0-.796.566.566 0 0 1 .795 0l.36.36 1.2-1.11a.566.566 0 0 1 .795.03.567.567 0 0 1-.03.796ZM12.565 2.67V1.5a.567.567 0 0 0-.563-.563.567.567 0 0 0-.562.563v1.125H6.565V1.5a.567.567 0 0 0-.563-.563.567.567 0 0 0-.562.563v1.17c-2.025.188-3.008 1.395-3.158 3.188a.377.377 0 0 0 .375.397h12.69c.218 0 .398-.188.375-.397-.15-1.793-1.132-3-3.157-3.188Z'
    />
    <path
      fill='#fff'
      d='M15 7.379H3a.752.752 0 0 0-.75.75v4.62c0 2.25 1.125 3.75 3.75 3.75h3.697a.749.749 0 0 0 .713-.99c-.15-.435-.277-.915-.277-1.26a4.132 4.132 0 0 1 4.125-4.125c.217 0 .435.015.645.052a.746.746 0 0 0 .854-.735V8.136A.765.765 0 0 0 15 7.38Zm-8.093 6.277a.789.789 0 0 1-.532.218.789.789 0 0 1-.532-.218.789.789 0 0 1-.218-.532c0-.195.082-.39.218-.533a.774.774 0 0 1 .247-.157.75.75 0 0 1 .817.157.789.789 0 0 1 .218.533c0 .195-.082.39-.218.532Zm0-2.625-.112.09a.568.568 0 0 1-.135.068.477.477 0 0 1-.135.045c-.053.007-.105.015-.15.015a.789.789 0 0 1-.532-.218.789.789 0 0 1-.218-.532c0-.195.082-.39.218-.533a.757.757 0 0 1 .682-.202c.045.007.09.022.135.045.045.015.09.037.135.067l.112.09a.789.789 0 0 1 .218.533c0 .195-.082.39-.218.532Zm2.626 0A.789.789 0 0 1 9 11.25a.789.789 0 0 1-.533-.218.789.789 0 0 1-.217-.532c0-.195.082-.39.217-.533.285-.277.788-.277 1.066 0a.789.789 0 0 1 .217.533c0 .195-.082.39-.217.532Z'
    />
  </svg>
)
export default CalendarWhite
