import React from 'react'
import { IGrade } from '../../../../types/types'
import { useTranslation } from 'react-i18next'

export interface IProps {
  item: IGrade
}

function GradeCard({ item }: IProps) {
  const { t, i18n } = useTranslation()
  return (
    <div className='courses-card'>
      <div className='courses-card__head'>
        <div className='courses-card__name'>{item?.name?.[i18n.language]}</div>
        <div className='courses-card__length'>
          {item?.age_recommendation?.[i18n.language]} {t('years')}
        </div>
      </div>
      <div
        className='courses-card__text'
        dangerouslySetInnerHTML={{ __html: item?.description?.[i18n.language] }}
      ></div>
    </div>
  )
}

export default GradeCard
