import React from 'react'
import SwiperCore from 'swiper'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination, Autoplay])

interface TaklifSliderProps {
  onClick: () => void
}

export const TaklifSlider: React.FC<TaklifSliderProps> = ({ onClick }) => {
  const swiperOptions = {
    spaceBetween: 10,
    loop: true,
    navigation: {
      nextEl: '.taklif-right',
      prevEl: '.taklif-left'
    },
    // autoplay: {
    //   delay: 20000, // автоматическая смена слайдов каждые 5 секунд
    // },
    breakpoints: {
      320: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 20,
        freeMode: false
      },
      768: {
        slidesPerView: 4.12,
        slidesPerGroup: 1,
        spaceBetween: 10,
        freeMode: false
      }
    }
  }

  return (
    <div className='home-recomended'>
      <div className='home-recent__head'>
        <div className='home-recent__title'>Taklif qilinayotgan darslar</div>
        <div className='arrows'>
          <span className='arrow-left taklif-left'>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M14 8L10 12L14 16'
                stroke='currentColor'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </span>
          <span className='arrow-right taklif-right'>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M10 16L14 12L10 8'
                stroke='currentColor'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </span>
        </div>
      </div>
      {/* Swiper */}
      <Swiper {...swiperOptions} className='home-recomended__carousel'>
        <SwiperSlide className='lesson'>
          <div className='lesson__img'>
            <img src={require('../../../../../assets/img/mock/lesson.jpg')} alt='lesson' />
          </div>
          <div className='lesson-info'>
            <div className='lesson__name'>ELA Advanced</div>
            <a href='#' className='lesson__btn btn btn-trans'>
              Batafsil
            </a>
            <a href='#' className='lesson__link'></a>
          </div>
        </SwiperSlide>
        <SwiperSlide className='lesson'>
          <div className='lesson__img'>
            <img src={require('../../../../../assets/img/mock/lesson.jpg')} alt='lesson' />
          </div>
          <div className='lesson-info'>
            <div className='lesson__name'>ELA Advanced</div>
            <a href='#' className='lesson__btn btn btn-trans'>
              Batafsil
            </a>
            <a href='#' className='lesson__link'></a>
          </div>
        </SwiperSlide>
        <SwiperSlide className='lesson'>
          <div className='lesson__img'>
            <img src={require('../../../../../assets/img/mock/lesson.jpg')} alt='lesson' />
          </div>
          <div className='lesson-info'>
            <div className='lesson__name'>ELA Advanced</div>
            <a href='#' className='lesson__btn btn btn-trans'>
              Batafsil
            </a>
            <a href='#' className='lesson__link'></a>
          </div>
        </SwiperSlide>
        <SwiperSlide className='lesson'>
          <div className='lesson__img'>
            <img src={require('../../../../../assets/img/mock/lesson.jpg')} alt='lesson' />
          </div>
          <div className='lesson-info'>
            <div className='lesson__name'>ELA Advanced</div>
            <a href='#' className='lesson__btn btn btn-trans'>
              Batafsil
            </a>
            <a href='#' className='lesson__link'></a>
          </div>
        </SwiperSlide>
        <SwiperSlide className='lesson'>
          <div className='lesson__img'>
            <img src={require('../../../../../assets/img/mock/lesson.jpg')} alt='lesson' />
          </div>
          <div className='lesson-info'>
            <div className='lesson__name'>ELA Advanced</div>
            <a href='#' className='lesson__btn btn btn-trans'>
              Batafsil
            </a>
            <a href='#' className='lesson__link'></a>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}
