import TeachersEach from "./component/teachers-each";
import TeachersWrapper from "./component/teachers-wrapper";

export const Teachers = () => (
  <>
    <form className='filter'>
      <div className='filter__head'>
        <div className='filter__wrap'>
          <div className='filter__title'>Kimni qidirmoqdasiz?</div>
          <div className='filter-search'>
            <div className='search-input'>
              <input type='text' className='search-input__input' placeholder='Qidirish ...' />
              <svg
                width={20}
                height={20}
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className='search-input__ico'
              >
                <path
                  d='M18.3327 18.3337L16.666 16.667M9.58268 17.5003C10.6223 17.5003 11.6518 17.2956 12.6123 16.8977C13.5728 16.4999 14.4455 15.9167 15.1806 15.1816C15.9157 14.4465 16.4989 13.5737 16.8967 12.6132C17.2946 11.6527 17.4993 10.6233 17.4993 9.58366C17.4993 8.54403 17.2946 7.51458 16.8967 6.55408C16.4989 5.59359 15.9157 4.72086 15.1806 3.98573C14.4455 3.2506 13.5728 2.66746 12.6123 2.26961C11.6518 1.87176 10.6223 1.66699 9.58268 1.66699C7.48305 1.66699 5.46942 2.50107 3.98475 3.98573C2.50009 5.47039 1.66602 7.48403 1.66602 9.58366C1.66602 11.6833 2.50009 13.6969 3.98475 15.1816C5.46942 16.6662 7.48305 17.5003 9.58268 17.5003V17.5003Z'
                  stroke='currentColor'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </div>
        </div>
        <div className='filter-gender'>
          <label htmlFor='male'>
            <input type='radio' name='gender' id='male' />
            <span className='btn btn-white'>Erkak</span>
          </label>
          <label htmlFor='female'>
            <input type='radio' name='gender' id='female' />
            <span className='btn btn-white'>Ayol</span>
          </label>
          <label htmlFor='foreign' className='filter-gender__foreign'>
            <input type='checkbox' name='foreign' id='foreign' />
            <span className='btn btn-white'>Chet ellik o’qituvchi</span>
          </label>
        </div>
      </div>
      <div className='filter__main'>
        <div className='filter-date'>
          <div className='filter-title'>Sana</div>
          <div className='filter-wrap'>
            <input type='text' />
            <input type='text' />
          </div>
        </div>
        <div className='filter-time'>
          <div className='filter-title'>Vaqt</div>
          <div className='filter-wrap'>
            <input type='text' />
            <input type='text' />
          </div>
        </div>
        <div className='filter-days'>
          <div className='filter-title'>Hafta kuni</div>
          <div className='filter-wrap'>
            <label htmlFor='day1'>
              <input type='checkbox' name='week' id='day1' />
              <span>Du</span>
            </label>
            <label htmlFor='day2'>
              <input type='checkbox' name='week' id='day2' />
              <span>Se</span>
            </label>
            <label htmlFor='day3'>
              <input type='checkbox' name='week' id='day3' />
              <span>Ch</span>
            </label>
            <label htmlFor='day4'>
              <input type='checkbox' name='week' id='day4' />
              <span>Pa</span>
            </label>
            <label htmlFor='day5'>
              <input type='checkbox' name='week' id='day5' />
              <span>Ju</span>
            </label>
            <label htmlFor='day6'>
              <input type='checkbox' name='week' id='day6' />
              <span>Sh</span>
            </label>
            <label htmlFor='day7'>
              <input type='checkbox' name='week' id='day7' />
              <span>Ya</span>
            </label>
          </div>
        </div>
      </div>
      <div className='filter-courses'>
        <div className='filter-title'>Kurslar</div>
        <div className='filter-wrap'>
          <label htmlFor='th1'>
            <input type='checkbox' name='courses' id='th1' />
            <span>Math Standard</span>
          </label>
          <label htmlFor='fr2'>
            <input type='checkbox' name='courses' id='fr2' />
            <span>Phonics Bootcamp</span>
          </label>
          <label htmlFor='sa3'>
            <input type='checkbox' name='courses' id='sa3' />
            <span>Story Time</span>
          </label>
        </div>
      </div>
    </form>
    <TeachersWrapper>
        <TeachersEach/>
    </TeachersWrapper>
    <div className='pagination'>
      <a href='#' className='arrow arrow-left'>
        <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M14 8L10 12L14 16'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </a>
      <ul>
        <li>
          <a href='#' className='current'>
            1
          </a>
        </li>
        <li>
          <a href='#'>2</a>
        </li>
        <li>
          <a href='#'>3</a>
        </li>
        <li>
          <a href='#'>4</a>
        </li>
      </ul>
      <span> ... </span>
      <a href='#' className='arrow arrow-right'>
        <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M10 16L14 12L10 8'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </a>
    </div>
  </>
)
