import React from 'react'
import { IWritingLesson } from '../../../../types/types'
import { useTranslation } from 'react-i18next'
import { MEDIA_URL } from '../../../../configs/request'
import useDownloader from 'react-use-downloader'

interface IProps {
  item: IWritingLesson
}
function WritingLessonCard(props: IProps) {
  const { t, i18n } = useTranslation()
  const { download } = useDownloader()

  return (
    <div className='lesson'>
      <div className='lesson__img'>
        <img src={MEDIA_URL + props.item?.photo} alt={props.item?.title?.[i18n.language]} />
      </div>
      <div className='lesson__tag'>{t('Free')}</div>
      <div className='lesson-info'>
        <div className='lesson__name'>{props.item?.title?.[i18n.language]}</div>
        <div
          className='lesson__text'
          dangerouslySetInnerHTML={{ __html: props.item?.description?.[i18n.language] }}
        ></div>
        <button
          className='lesson__btn btn btn-trans'
          onClick={() =>
            download(
              MEDIA_URL + props?.item?.file,
              props?.item?.file?.split('/')?.[props?.item?.file?.split('/')?.length - 1]
            )
          }
        >
          {t('Download')}
        </button>
        <a href='#' className='lesson__link'></a>
      </div>
    </div>
  )
}

export default WritingLessonCard
