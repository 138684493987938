export const Classes = () => (
  <>
    <div className='classes-head'>
      <li className='current'>Complete Class</li>
      <li>Coming classes</li>
    </div>
    <div className='classes-main'>
      <div className='classes__title'>Incomplete class</div>
      <div className='classes-main__incomplete'>
        <div className='history-table'>
          <div className='history-table__head'>
            <div>Class ID</div>
            <div>Date time</div>
            <div>Programm</div>
            <div>Lesson</div>
            <div>Students</div>
            <div></div>
          </div>
          <div className='history-table__row'>
            <div className='history-table__class'>gsa-class-1707052</div>
            <div className='history-table__date'>November 5th,2022 19:30</div>
            <div className='history-table__programm'>Game-Based Learning</div>
            <div className='history-table__lesson'>(25 minutes) Elementary-2-18</div>
            <div className='history-table__student'>Climbung Wall</div>
            <a href='classes-single.html' className='history-table__btns'>
              <button className='btn'>Lesson plan</button>
            </a>
          </div>
          <div className='history-table__row'>
            <div className='history-table__class'>gsa-class-1707052</div>
            <div className='history-table__date'>November 5th,2022 19:30</div>
            <div className='history-table__programm'>Game-Based Learning</div>
            <div className='history-table__lesson'>(25 minutes) Elementary-2-18</div>
            <div className='history-table__student'>Climbung Wall</div>
            <a href='classes-single.html' className='history-table__btns'>
              <button className='btn'>Lesson plan</button>
            </a>
          </div>
        </div>
      </div>
      <div className='classes-main__complete'>
        <div className='classes__title'>Complete class</div>
        <div className='history-table'>
          <div className='history-table__row'>
            <div className='history-table__date'>November 5th,2022 19:30</div>
            <div className='history-table__programm'>Game-Based Learning</div>
            <div className='history-table__lesson'>Elementary-3-17 Airplane</div>
            <div className='history-table__student'>Climbung Wall</div>
            <div className='history-table__btns'>
              <button className='btn'>Lesson plan</button>
              <button className='btn btn-white'>Completed</button>
            </div>
          </div>
          <div className='history-table__row'>
            <div className='history-table__date'>November 5th,2022 19:30</div>
            <div className='history-table__programm'>Game-Based Learning</div>
            <div className='history-table__lesson'>Elementary-3-17 Airplane</div>
            <div className='history-table__student'>Climbung Wall</div>
            <div className='history-table__btns'>
              <button className='btn'>Lesson plan</button>
              <button className='btn btn-white'>Completed</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* РАЗДЕЛЕНИЕ */}
    <div className='classes-coming'>
      <div className='classes-coming__carousel'>
        <div className='classes-big'>
          <div className='classes-big__name'>
            Games-Based Learning
            <span>Elementary-2-17 Basketball Court November 19th, 2022 18:00 (25 minutes)</span>
          </div>
          <div className='classes-big__card'>
            <div className='classes-big__student'>Ryosuke Negi</div>
            <div className='classes-big__age'>9 years old</div>
            <div className='classes-big__wrap'>
              <div className='classes-big__desc'>
                2022/9/10 To GBL teachers, Please encourage him to speak up. If he doesn’t answer your question, start
                from simple questions that he can answ er yes/no. Please repeat the question, and to persist in case he
                doesn’t answer !!
              </div>
              <div className='classes-big__btns'>
                <button className='btn btn-green'>Student view</button>
                <button className='btn btn-green'>Lesson plan</button>
                <button className='btn'>Enter</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='classes-coming__arrows'>
        <div className='classes-coming__prev'>
          <div className='arrow-left'>
            <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M14 16L10 12L14 8'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <span>Sat</span>
        </div>
        <div className='classes-coming__date'>
          Noyabr 2022 (2022-10-29)
          <span>Sun</span>
        </div>
        <div className='classes-coming__next'>
          <span>Mon</span>
          <div className='arrow-right'>
            <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M10 16L14 12L10 8'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
        </div>
      </div>
      <div className='history-table'>
        <div className='history-table__row'>
          <div className='history-table__date'>
            05:00am Elementary-3-13 Street light Manial (25 minutes) [Game-Based Learning]
          </div>
          <div className='history-table__programm'>Kousel Fujiseki</div>
          <div className='history-table__btns'>
            <a href='classes-student.html' className='btn'>
              Student view
            </a>
            <a href='classes-single.html' className='btn btn-white'>
              Lesson plan
            </a>
          </div>
        </div>
        <div className='history-table__row'>
          <div className='history-table__date'>
            05:00am Elementary-3-13 Street light Manial (25 minutes) [Game-Based Learning]
          </div>
          <div className='history-table__programm'>Kousel Fujiseki</div>
          <div className='history-table__btns'>
            <a href='classes-student.html' className='btn'>
              Student view
            </a>
            <a href='classes-single.html' className='btn btn-white'>
              Lesson plan
            </a>
          </div>
        </div>
        <div className='history-table__row'>
          <div className='history-table__date'>
            05:00am Elementary-3-13 Street light Manial (25 minutes) [Game-Based Learning]
          </div>
          <div className='history-table__programm'>Kousel Fujiseki</div>
          <div className='history-table__btns'>
            <a href='classes-student.html' className='btn'>
              Student view
            </a>
            <a href='classes-single.html' className='btn btn-white'>
              Lesson plan
            </a>
          </div>
        </div>
      </div>
    </div>
  </>
)
