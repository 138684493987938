import React, { SVGProps } from 'react'

function Notification(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M19.5 8C20.163 8 20.7989 7.73661 21.2678 7.26777C21.7366 6.79893 22 6.16304 22 5.5C22 4.83696 21.7366 4.20107 21.2678 3.73223C20.7989 3.26339 20.163 3 19.5 3C18.837 3 18.2011 3.26339 17.7322 3.73223C17.2634 4.20107 17 4.83696 17 5.5C17 6.16304 17.2634 6.79893 17.7322 7.26777C18.2011 7.73661 18.837 8 19.5 8Z'
        fill='currentColor'
      />
      <path
        d='M19.5 8C20.163 8 20.7989 7.73661 21.2678 7.26777C21.7366 6.79893 22 6.16304 22 5.5C22 4.83696 21.7366 4.20107 21.2678 3.73223C20.7989 3.26339 20.163 3 19.5 3C18.837 3 18.2011 3.26339 17.7322 3.73223C17.2634 4.20107 17 4.83696 17 5.5C17 6.16304 17.2634 6.79893 17.7322 7.26777C18.2011 7.73661 18.837 8 19.5 8Z'
        fill='currentColor'
      />
      <path
        d='M20.72 9.31C20.02 9.53 19.25 9.57 18.45 9.37C17.7841 9.19202 17.176 8.84405 16.6851 8.3602C16.1942 7.87635 15.8375 7.27324 15.65 6.61C15.47 5.96 15.46 5.32 15.57 4.74C15.7 4.1 15.25 3.5 14.61 3.5H7C4 3.5 2 5 2 8.5V15.5C2 19 4 20.5 7 20.5H17C20 20.5 22 19 22 15.5V10.26C22 9.6 21.36 9.1 20.72 9.31ZM15.52 11.15L14.34 12.09C13.68 12.62 12.84 12.88 12 12.88C11.16 12.88 10.31 12.62 9.66 12.09L6.53 9.59C6.377 9.463 6.27907 9.28173 6.2567 9.08415C6.23433 8.88657 6.28927 8.68799 6.41 8.53C6.67 8.21 7.14 8.15 7.46 8.41L10.59 10.91C11.35 11.52 12.64 11.52 13.4 10.91L14.58 9.97C14.9 9.71 15.38 9.76 15.63 10.09C15.89 10.41 15.84 10.89 15.52 11.15Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Notification
