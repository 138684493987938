import * as React from "react"
import { SVGProps } from "react"
const User = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M9.997 10.001a4.167 4.167 0 1 0 0-8.333 4.167 4.167 0 0 0 0 8.333Zm0 2.084c-4.175 0-7.575 2.8-7.575 6.25 0 .233.183.416.417.416h14.316a.413.413 0 0 0 .417-.416c0-3.45-3.4-6.25-7.575-6.25Z"
    />
  </svg>
)
export default User
