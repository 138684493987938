export const TeacherReviewsSingle = () => (
  <div className='reviews-single'>
    <div className='reviews-single__head'>
      <a href='./' className='reviews-single__back'>
        <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M9.57 18.0703L3.5 12.0003L9.57 5.93031M20.5 12.0003L3.67 12.0003'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeMiterlimit={10}
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </a>
      {/*  */}
      <div className='reviews-single__name'>Nicole Cilliers</div>
      <div className='course-type'>
        <span className='course-type__ico'>
          <svg width={18} height={18} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M6.375 7.87477H11.625M5.25 13.8223H8.25L11.5875 16.0423C11.7001 16.1173 11.831 16.1604 11.9662 16.167C12.1015 16.1735 12.2359 16.1432 12.3552 16.0793C12.4746 16.0154 12.5743 15.9203 12.6438 15.8041C12.7134 15.688 12.7501 15.5551 12.75 15.4198V13.8223C15 13.8223 16.5 12.3223 16.5 10.0723V5.57227C16.5 3.32227 15 1.82227 12.75 1.82227H5.25C3 1.82227 1.5 3.32227 1.5 5.57227V10.0723C1.5 12.3223 3 13.8223 5.25 13.8223V13.8223Z'
              stroke='currentColor'
              strokeWidth='1.5'
              strokeMiterlimit={10}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </span>
        <span>Speaking Course</span>
      </div>
    </div>
    <ul className='reviews-single__list'>
      <li>
        {/* <img src={require('../../assets/img/mock/student.jpg')} alt='student' /> */}
        <p>
          "Hi Laziza, good job completing today's lesson! You are doing great! Good job expressing about yourself during
          the free-talk as well! Keep up the good work and see you in the next class! Teacher Hassna 💖"
        </p>
      </li>
      <li>
        {/* <img src={require('../../assets/img/mock/student.jpg')} alt='student' /> */}
        <p>
          "🙋Hello Laziza🙋 It was great speaking with you again !!!⛄ In was nice hearing about school and your day. We
          read your last answers well adn then today we spoke about the weather in your country / city and how it can
          effect the roads 💌Great work as always !!! Thank you for the amazing answers. 📘No homework but you can read
          today's questions if you want. 😇I'll see you again next time , thank you Laziza Link:📎
          <a href='https://docs.google.com/document/d/1qF2tdtcnexgFsaKEEh-HxkL0e2RehSsWHa2nZTfYPF8/edit?usp=sharing📎'>
            "https://docs.google.com/document/d/1qF2tdtcnexgFsaKEEh-HxkL0e2RehSsWHa2nZTfYPF8/edit?usp=sharing📎"
          </a>
        </p>
      </li>
    </ul>
  </div>
)
