import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { IMenu } from '../..'

function SideMenuItem(props: IMenu) {
  const location = useLocation()
  return (
    <li>
      <NavLink
        to={`/${location.pathname?.split('/')?.[1]}${props.path}`}
        className={({ isActive, isPending }) => (isPending ? 'pending' : isActive ? 'active' : '')}
      >
        {props.icon}
        <span>{props.title}</span>
      </NavLink>
    </li>
  )
}

export default SideMenuItem
