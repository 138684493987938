import React,{FC} from 'react'
import User from '../../../../assets/img/icons/User'
import CalendarWhite from '../../../../assets/img/icons/CalendarWhite'
import SwiperCore from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Navigation, Pagination, Autoplay]);


export const StoryTime:FC = () => {
    const swiperOptions = {
        spaceBetween: 10,
        loop: true,
        navigation: {
          nextEl: '.taklif-right',
          prevEl: '.taklif-left',
        },
        // autoplay: {
        //   delay: 20000, // автоматическая смена слайдов каждые 5 секунд
        // },
        breakpoints:{
            320:{
              slidesPerView: 1,
              slidesPerGroup: 1,
              spaceBetween: 20,
              freeMode:false,
            },
            768:{
              slidesPerView: 1.32,
              slidesPerGroup: 1,
              spaceBetween: 10,
              freeMode:false,
            }
      }
      }

      
  return (
    <Swiper {...swiperOptions} className='profile-cards'>
         <SwiperSlide className='profile-card'>
         <div className='profile-card__head'>
        <div className='profile-card__name'>Story Time</div>
        <div className='profile-card__count'>
          <User />
          <span>14</span>
        </div>
      </div>
      <div className='profile-card__date'>
        <CalendarWhite />
        <span>14 December 15:30</span>
      </div>
      <div className='profile-card__join'>
        <span>0 years old++</span>
        <a href='#' className='btn btn-white'>
          Batafsil..
        </a>
      </div>
         </SwiperSlide>
         <SwiperSlide className='profile-card'>
         <div className='profile-card__head'>
        <div className='profile-card__name'>Story Time</div>
        <div className='profile-card__count'>
          <User />
          <span>14</span>
        </div>
      </div>
      <div className='profile-card__date'>
        <CalendarWhite />
        <span>14 December 15:30</span>
      </div>
      <div className='profile-card__join'>
        <span>0 years old++</span>
        <a href='#' className='btn btn-white'>
          Batafsil..
        </a>
      </div>
         </SwiperSlide>
    </Swiper>
    
  )
}

