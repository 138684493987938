import Payment1 from '../../../assets/img/mock/Payment1'
import Payment2 from '../../../assets/img/mock/Payment2'
import Payment3 from '../../../assets/img/mock/Payment3'

export const Payment = () => (
  <ul className='payment-list'>
    <a href='#' className='payment-item click'>
      <Payment1 />
      <span>Hamyonni Click orqali to’ldirish</span>
    </a>
    <a href='#' className='payment-item payme'>
      <Payment2 />
      <span>Hamyonni Payme orqali to’ldirish</span>
    </a>
    <a href='#' className='payment-item paynet'>
      <Payment3 />
      <span>Hamyonni Paynet orqali to’ldirish</span>
    </a>
  </ul>
)
