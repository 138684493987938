import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type CategoryInput = {
  name: string
  label: string
  control: Control
  error: any
}

const CustomDateInput: React.FC<CategoryInput> = ({ name, label, control, error }) => {
  const { t } = useTranslation()

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({ field }) => (
        <div className='login-form__item'>
          <div className='login-form__name'>{t(label)}</div>
          <div className='login-form__input'>
            <input type='date' value={field.value} onChange={e => field.onChange(e.target.value)} />
          </div>
          {error?.message && <div className='login-form__advice'>{t(String(error?.message))}</div>}
        </div>
      )}
    />
  )
}

export default CustomDateInput
